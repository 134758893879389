import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import {
  emailPattern,
  phoneNumberPattern,
  sixDigitPostalPattern,
} from '@app/regex';
import { defaultProfilePic } from '@config/asset.config';
import { Status } from '@models/status.model';
import { RequestService } from '@services/index';
import { passwordValidator } from './password.validator';
import { IVendorInfo, IVendorInfoKeys } from './vendor.model';
@Component({
  selector: 'fh-vendor-detail',
  templateUrl: './vendor-detail.component.html',
  styleUrls: ['./vendor-detail.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    MatMenuModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
  ],
})
export class VendorDetailComponent implements OnInit {
  Status = Status;
  checked = false;
  error = '';
  success = '';
  isSubmitted = false;
  countries: any[] = [];
  countryID = 2;
  token = '';
  vendorId = 0;
  email_msg = '';
  country_err_msg = '';
  button_disabled = false;
  defaultProfilePic = defaultProfilePic;
  profilePic = defaultProfilePic;
  uploadImageMessage = '';
  fileToUpload!: File | null;
  inputForm!: UntypedFormGroup;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private auth: RequestService
  ) {}

  ngOnInit(): void {
    this.vendorId = parseInt(this.route.snapshot.paramMap.get('id')!, 10);
    this.getCountryList();

    const formData: Record<IVendorInfoKeys, any> = {
      first_name: ['', Validators.required],
      last_name: [''],
      street: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: [
        '',
        [Validators.required, Validators.pattern(sixDigitPostalPattern)],
      ],
      email: ['', [Validators.required, Validators.pattern(emailPattern)]],
      mobile: [
        '',
        [
          Validators.required,
          Validators.pattern(phoneNumberPattern),
          Validators.minLength(8),
        ],
      ],
      password: ['', [passwordValidator]],
      standardDeliveryFee: [0, [Validators.required]],
      defaultTranshipperMarkupFee: [0, [Validators.required]],
      status: [Status.Active, [Validators.required]],
      isStarSeller: ['No', [Validators.required]],
    };
    this.inputForm = this.formBuilder.group(formData);

    if (this.vendorId) {
      this.auth
        .get('/api/v1/merchant?id=' + this.vendorId)
        .subscribe((res: any) => {
          if (res.status == '1') {
            const vendor: IVendorInfo = {
              first_name: res.data.first_name?.trim() || '',
              last_name: res.data.last_name?.trim() || '',
              street: res.data.address_line1?.trim() || '',
              city: res.data.city?.trim() || '',
              state: res.data.state?.trim() || '',
              email: res.data.email?.trim() || '',
              mobile: res.data.phone_num?.trim() || '',
              password: '',
              status: res.data.status?.trim() as Status,
              standardDeliveryFee: res.data.standard_delivery_fee || 0,
              postalCode: res.data.postal_code || '',
              defaultTranshipperMarkupFee: res.data.destination_transhipper_markup,
              isStarSeller: res.data.is_star_seller?.trim() as 'No' | 'Yes',
            };
            this.inputForm.patchValue(vendor);
            this.password.clearValidators();
            this.countryID = +(res.data.country + '')?.trim();
            this.profilePic = res.data.profile_pic?.trim() || this.profilePic;
          } else {
            // localStorage.clear();
            this.router.navigate(['/vendors']);
          }
        });
    }
    // ------------------
  }

  get inputFormControls() {
    return this.inputForm.controls;
  }

  get password(): any {
    return this.inputForm.controls['password'];
  }
  // ---------------------

  slectOption(e: number) {
    this.countryID = e;
    this.country_err_msg = '';
  }

  getCountryList() {
    this.auth.get('/api/v1/countries').subscribe((res: any) => {
      if (res.status === true) {
        this.countries = res.data;
        // console.log(this.countries);
      }
    });
  }

  get standardDeliveryFee(): AbstractControl {
    return this.inputFormControls['standardDeliveryFee'];
  }

  get defaultTranshipperMarkupFee(): AbstractControl {
    return this.inputFormControls['defaultTranshipperMarkupFee'];
  }

  // profile pic update
  uploadProfilePicture(event: Event) {
    const input = event.target as HTMLInputElement;
    this.uploadImageMessage = 'uploading..';

    this.fileToUpload = input.files!.item(0);

    const fd = new FormData();
    fd.append('profile_pic', this.fileToUpload!);

    this.auth
      .put(`/api/v1/users/upload_profile_image?merchantId=${this.vendorId}`, fd)
      .subscribe((res: any) => {
        if (res.result_code === 200) {
          this.uploadImageMessage = 'uploaded';
          this.profilePic = res.data.profile_pic;
          setTimeout(() => {
            this.uploadImageMessage = '';
          }, 2000);
        } else {
          this.uploadImageMessage = res.message;
          setTimeout(() => {
            this.uploadImageMessage = '';
          }, 2000);
        }
      });
  }
  // --------------------------------------

  // Delete profile pic
  deleteProfilePicture() {
    this.uploadImageMessage = 'deleting..';

    this.auth
      .put(
        `/api/v1/users/delete_profile_image?merchantId=${this.vendorId}`,
        null
      )
      .subscribe((res: any) => {
        if (res.result_code === 200) {
          this.uploadImageMessage = 'deleted';
          this.profilePic = this.defaultProfilePic;
          setTimeout(() => {
            this.uploadImageMessage = '';
          }, 2000);
        } else {
          this.uploadImageMessage = res.message;
          setTimeout(() => {
            this.uploadImageMessage = '';
          }, 2000);
        }
      });
  }

  input() {
    this.isSubmitted = true;
    if (this.inputForm.invalid) {
      return;
    }
    if (this.countryID == 0) {
      this.country_err_msg = 'Please select country';
      return;
    } else this.country_err_msg = '';

    this.button_disabled = true;

    const requestData = {
      ...this.inputForm.value,
      country: this.countryID,
      vid: this.vendorId,
    };

    this.auth.post('/api/v1/vendor_input', requestData).subscribe((res: any) => {
      if (res.status == '1' || res.status == true) {
        this.router.navigate(['/vendors'], {
          state: { success_msg: 'Record saved successfully.' },
        });
      } else {
        if (res.message.trim() == 'Email already exist') {
          this.email_msg = res.message;
        } else {
          this.error = res.message;
        }

        this.button_disabled = false;

        setTimeout(() => {
          this.error = '';
          this.email_msg = '';
          this.country_err_msg = '';
        }, 5000);
      }
    });
  }
}
