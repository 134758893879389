<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-8 m-auto">
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">Vendor Input</h4>
          </div>
          <div class="card-body">
            @if (vendorId) {
            <section class="profile-section1">
              <div class="container">
                <div class="row">
                  <div class="col-6 col-sm-6">
                    <div class="profile-box-img">
                      <div class="profile-img-parent">
                        <img
                          width="200"
                          height="200"
                          src="{{ profilePic }}"
                          class="should_lazyload upload-profile-img"
                          alt="profile pic"
                        />
                      </div>
                      <div class=""></div>
                    </div>
                  </div>
                  <button
                    [matMenuTriggerFor]="menu"
                    class="btn btn-primary upload-img-icon-button"
                  >
                    Edit
                  </button>
                  <mat-menu
                    class="menu-upload-delete"
                    #menu="matMenu"
                    xPosition="before"
                  >
                    <button mat-menu-item (click)="fileUpload.click()">
                      Change
                    </button>
                    @if (profilePic !== defaultProfilePic) {
                    <button mat-menu-item (click)="deleteProfilePicture()">
                      Delete
                    </button>
                    }
                  </mat-menu>
                  <input
                    hidden="true"
                    type="file"
                    #fileUpload
                    id="fileUpload"
                    name="fileUpload"
                    accept="image/*"
                    (change)="uploadProfilePicture($event)"
                  />
                  <small>{{ uploadImageMessage }}</small>
                </div>
              </div>
            </section>
            } @if (inputForm) {
            <form [formGroup]="inputForm" (ngSubmit)="input()">
              <div class="row">
                <mat-form-field class="col-md-12">
                  <mat-label> First Name</mat-label>
                  <input
                    matInput
                    placeholder="First Name"
                    formControlName="first_name"
                    required
                  />
                  
                  @if (isSubmitted && inputFormControls['first_name'].errors) {
                  <mat-hint class="input-error">
                    @if (inputFormControls['first_name'].errors['required']) {
                    <span class="text-danger"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />This field is required.</span
                    >
                    }
                  </mat-hint>
                  }
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="col-md-12">
                  <mat-label>Last Name</mat-label>
                  <input
                    matInput
                    placeholder="Last Name"
                    formControlName="last_name"
                  />
                  @if (isSubmitted && inputFormControls['last_name'].errors) {
                  <mat-hint class="input-error">
                    @if (inputFormControls['last_name'].errors['required']) {
                    <span class="text-danger"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />This field is required.</span
                    >
                    }
                  </mat-hint>
                  }
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="col-md-12">
                  <mat-label>Email</mat-label>
                  <input
                    matInput
                    placeholder="Email"
                    formControlName="email"
                    required
                  />
                  @if (isSubmitted && inputFormControls['email'].errors) {
                  <mat-hint class="input-error">
                    @if (inputFormControls['email'].errors['required']) {
                    <span class="text-danger"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />This field is required.</span
                    >
                    } @if (inputFormControls['email'].errors['pattern']) {
                    <span class="text-danger"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />Email address invalid.</span
                    >
                    }
                  </mat-hint>
                  } @if (email_msg) {
                  <mat-hint class="input-error">
                    @if (email_msg) {
                    <span class="text-danger"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />{{ email_msg }}</span
                    >
                    }
                  </mat-hint>
                  }
                </mat-form-field>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="col-md-12">
                    <mat-label> Mobile</mat-label>
                    <input
                      matInput
                      placeholder="Mobile"
                      formControlName="mobile"
                      required
                    />
                    @if (isSubmitted && inputFormControls['mobile'].errors) {
                    <mat-hint class="input-error">
                      @if (inputFormControls['mobile'].errors['required']) {
                      <span class="text-danger">This field is required.</span>
                      } @if ( inputFormControls['mobile'].errors['minlength'] ||
                      inputFormControls['mobile'].errors['pattern ']) {
                      <span class="text-danger"
                        >Phone Number must be numbers & minimum 8 digit.</span
                      >
                      }
                    </mat-hint>
                    }
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="col-md-12">
                    <mat-label> Address</mat-label>
                    <input
                      matInput
                      placeholder="Address"
                      formControlName="street"
                      required
                    />
                    @if (isSubmitted && inputFormControls['street'].errors) {
                    <mat-hint class="input-error">
                      @if (inputFormControls['street'].errors['required']) {
                      <span class="text-danger">This field is required.</span>
                      }
                    </mat-hint>
                    }
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="col-md-12">
                    <mat-label> City</mat-label>
                    <input
                      matInput
                      placeholder="City"
                      formControlName="city"
                      required
                    />
                    @if (isSubmitted && inputFormControls['city'].errors) {
                    <mat-hint class="input-error">
                      @if (inputFormControls['city'].errors['required']) {
                      <span class="text-danger">This field is required.</span>
                      }
                    </mat-hint>
                    }
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="col-md-12">
                    <mat-label>State</mat-label>
                    <input
                      matInput
                      placeholder="State"
                      formControlName="state"
                      required
                    />
                    @if (isSubmitted && inputFormControls['state'].errors) {
                    <mat-hint class="input-error">
                      @if (inputFormControls['state'].errors['required']) {
                      <span class="text-danger">This field is required.</span>
                      }
                    </mat-hint>
                    }
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="w-100">
                    <mat-label>Country</mat-label>
                    <mat-select
                      [(ngModel)]="countryID"
                      [ngModelOptions]="{ standalone: true }"
                      (selectionChange)="slectOption($event.value)"
                    >
                      @for (row of countries; track row.id) {
                      <mat-option [value]="row.id">
                        {{ row.text }}
                      </mat-option>
                      }
                    </mat-select>
                    @if (country_err_msg) {
                    <mat-hint class="input-error">
                      @if (country_err_msg) {
                      <span class="text-danger">{{ country_err_msg }}</span>
                      }
                    </mat-hint>
                    }
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="w-100">
                    <mat-label>Postal code</mat-label>
                    <input
                      matInput
                      placeholder="Postal code"
                      formControlName="postalCode"
                      required
                    />
                    @if (isSubmitted && inputFormControls['postalCode'].errors)
                    {
                    <mat-hint class="input-error">
                      @if ( inputFormControls['postalCode'].errors['required']
                      || inputFormControls['postalCode'].errors['pattern']) {
                      <span class="text-danger"
                        >6 digit postal code is required.</span
                      >
                      }
                    </mat-hint>
                    }
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="col-md-12">
                    <mat-label>Default Delivery Fee</mat-label>
                    <input
                      matInput
                      placeholder="Default delivery fee"
                      formControlName="standardDeliveryFee"
                      required
                    />
                    @if (isSubmitted && standardDeliveryFee.errors) {
                    <mat-hint class="input-error">
                      @if (standardDeliveryFee.errors['required']) {
                      <span class="text-danger">This field is required.</span>
                      }
                    </mat-hint>
                    }
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="col-md-12">
                    <mat-label>Default transhipper Fee</mat-label>
                    <input
                      matInput
                      placeholder="Default transhipper markup fee"
                      formControlName="defaultTranshipperMarkupFee"
                      required
                    />
                    @if (isSubmitted && defaultTranshipperMarkupFee.errors) {
                    <mat-hint class="input-error">
                      @if (defaultTranshipperMarkupFee.errors['required']) {
                      <span class="text-danger">This field is required.</span>
                      }
                    </mat-hint>
                    }
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="w-100">
                    <mat-label>Is star seller</mat-label>
                    <mat-select formControlName="isStarSeller">
                      <mat-option value="No">No</mat-option>
                      <mat-option value="Yes">Yes</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="w-100">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status">
                      <mat-option [value]="Status.Active">Active</mat-option>
                      <mat-option [value]="Status.Inactive"
                        >Inactive</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="col-md-12">
                    <mat-label>Password</mat-label>
                    <input
                      type="password"
                      matInput
                      placeholder="Password"
                      formControlName="password"
                    />
                    @if (isSubmitted && password.errors) {
                    <mat-hint class="input-error">
                      <span class="text-danger"
                        >Please enter a valid password (minimum 8 chars).</span
                      >
                    </mat-hint>
                    }
                  </mat-form-field>
                </div>
              </div>
              <span class="text-danger">{{ error }}</span>
              <span class="text-success">{{ success }}</span>
              <button
                mat-raised-button
                type="submit"
                [disabled]="button_disabled"
                class="btn btn-danger pull-right"
              >
                SAVE
              </button>
              <div class="clearfix"></div>
            </form>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
