import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatNavList } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterLink } from '@angular/router';
import { shortPath } from '@app/app-routes.const';
import { AppStateService } from '@services/index';
import { RequestService } from '@services/request/request.service';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'dashboard',
    class: '',
  },
  {
    path: '/vendor_input/0',
    title: 'Add Vendor',
    icon: 'person_add',
    class: '',
  },
  {
    path: '/vendors',
    title: 'Vendor List',
    icon: 'people',
    class: '',
  },
  {
    path: '/user_list',
    title: 'User List',
    icon: 'people',
    class: '',
  },
  {
    path: '/category_input/0',
    title: 'Add Category',
    icon: 'category',
    class: '',
  },
  {
    path: '/categories',
    title: 'Category List',
    icon: 'list_alt',
    class: '',
  },
  {
    path: '/product_input/0',
    title: 'Add Product',
    icon: 'add_box',
    class: '',
  },
  {
    path: '/products',
    title: 'Product List',
    icon: 'inventory',
    class: '',
  },
  {
    path: '/orders',
    title: 'Orders List',
    icon: 'shopping_cart',
    class: '',
  },
  {
    path: '/pending-order-list',
    title: 'Pending Orders List',
    icon: 'hourglass_empty',
    class: '',
  },
  {
    path: '/recommendations',
    title: 'Recommendation List',
    icon: 'thumb_up',
    class: '',
  },
  {
    path: '/admin/merchantOrders',
    title: 'Merchant Order List',
    icon: 'local_shipping',
    class: '',
  },
  {
    path: '/campaign',
    title: 'Campaign',
    icon: 'campaign',
    class: '',
  },
  {
    path: '/offer',
    title: 'Offer List',
    icon: 'local_offer',
    class: '',
  },
  {
    path: '/auction',
    title: 'Auction',
    icon: 'gavel',
    class: '',
  },
  {
    path: `/${shortPath.merchantDeliveryLogic}`,
    title: 'Manage Delivery Text',
    icon: 'emoji_transportation',
    class: '',
  },
];

@Component({
  selector: 'fh-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
  standalone: true,
  imports: [
    MatSidenavModule,
    MatIconModule,
    TitleCasePipe,
    MatToolbarModule,
    AsyncPipe,
    RouterLink,
    MatNavList
],
})
export class MainNavComponent implements OnDestroy, OnInit {
  access: string = localStorage.getItem('aqua_access')!;
  token = '';
  menuItems: any[];
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  tokenSubs!: Subscription;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private auth: RequestService,
    private appStateService: AppStateService,
    private cdr: ChangeDetectorRef
  ) {
    this.menuItems = ROUTES;
  }

  ngOnInit() {
    this.tokenSubs = this.appStateService.userToken$.subscribe((val) => {
      this.token = val;
      // this.checkRoutes();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.tokenSubs.unsubscribe();
  }

  logout() {
    if (localStorage.getItem('aqua_auth_token')) {
      this.router.navigate(['login']);
    }

    console.log('logout call..');
    this.auth
      .delete('/api/v1/users/logout')
      .subscribe((res: any) => {
        if (res.result_code === '1') {
          localStorage.clear();
          this.router.navigate(['login']);
          this.appStateService.userToken$.next('');
        } else {
          localStorage.clear();
          this.router.navigate(['login']);
          this.appStateService.userToken$.next('');
        }
      });
  }
}
