<mat-form-field class="example-form-field">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-grid #chipGrid aria-label="Select options">
    @for (option of selectItems; track option) {
    <mat-chip-row (removed)="removeOption(option)">
      {{ option }}
      <button matChipRemove aria-label="'remove ' + option">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    }
  </mat-chip-grid>
  <input
    placeholder="New option..."
    [matChipInputFor]="chipGrid"
    (matChipInputTokenEnd)="addOption($event)"
  />
</mat-form-field>
