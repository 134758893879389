import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { RequestService } from '@services/request/request.service';
import { map, switchMap } from 'rxjs';

@Component({
  selector: 'fh-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.css'],
  standalone: true,
  imports: [
    MatInputModule,
    MatMenuModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    RouterLink,
    MatPaginatorModule,
    MatIcon,
  ],
})
export class VendorListComponent implements OnInit {
  list: any;
  token = '';
  checked = false;
  success_msg = '';
  error_msg = '';
  zones: any;
  totalRecords!: number;
  pageSize = 0;
  pageIndex = 0;
  searchText = '';
  paramsAndQueryParams = this.activatedRoute.params.pipe(
    switchMap((params) => {
      return this.activatedRoute.queryParams.pipe(
        map((queryParams) => {
          return { params, queryParams };
        })
      );
    })
  );
  constructor(
    private router: Router,
    private auth: RequestService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.paramsAndQueryParams.subscribe((data) => {
      this.searchText = data.queryParams['search'] || '';
      this.pageSize = data.queryParams['pageSize'] || 10;
      this.pageIndex = data.queryParams['pageIndex'] || 0;

      this.getVendors();
    });
  }

  getVendors() {
    const searchText = this.searchText;
    this.auth
      .get(
        `/api/v1/vendor-list?pageSize=${this.pageSize}&pageIndex=${this.pageIndex}&search=${searchText}`
      )
      .subscribe((res: any) => {
        if (res.status === true) {
          const vendor = res.data.list;
          this.totalRecords = res.data.count;
          this.get_zones(vendor);
        }
      });
  }

  get_zones(vendor: any) {
    this.auth.get('/api/v1/get_zones').subscribe((res: any) => {
      if (res.status === true) {
        this.zones = res.data;
        this.show_zones(vendor);
      }
    });
  }

  show_zones(vendor: any) {
    vendor.forEach((element: { [x: string]: string; id: string }) => {
      if (Object.keys(this.zones).includes(element.id.toString())) {
        element['zone'] = this.zones[element.id.toString()];
      } else {
        element['zone'] = ' ';
      }
    });
    this.list = vendor;
  }

  onPageChange(change: PageEvent) {
    this.router.navigateByUrl(
      `/vendors?search=${this.searchText}&pageIndex=${change.pageIndex}&pageSize=${this.pageSize}`
    );
  }

  searchVendors(searchText: string) {
    this.pageIndex = 0
    this.router.navigateByUrl(
      `/vendors?search=${searchText}&pageIndex=${this.pageIndex}&pageSize=${this.pageSize}`
    );
  }
}
