import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatChipInputEvent, MatChipListboxChange, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'fh-chips-multi-select',
  templateUrl: './chips-multi-select.component.html',
  styleUrls: ['./chips-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsMultiSelectComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [MatFormFieldModule, MatChipsModule, MatIconModule, ReactiveFormsModule],
})
export class ChipsMultiSelectComponent implements ControlValueAccessor, OnChanges, OnInit {
  
  @Input() existingValues: string[] = [];
  @Input() label = '';
  @Output() selectionChange = new EventEmitter<string[]>();

  selectItems: string[] = [];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value: string[]) => void = () => {};


  ngOnInit(): void {
    if (this.existingValues) {
      this.selectItems = this.existingValues;
      this.onChange(this.selectItems);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['existingValues']) {
      this.selectItems = changes['existingValues'].currentValue || [];
      this.onChange(this.selectItems);
    }
  }

  writeValue(value: string[]): void {
    if (value) {
      console.log(value)
      this.selectItems = value;
    } else {
      this.selectItems = [];
    }
  }
  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDisabledState(isDisabled: boolean): void {
    // Handle the disabled state if necessary
  }
  onSelectionChange(event: MatChipListboxChange) {
    this.selectItems = event.value as string[];
    this.onChange(this.selectItems);
    this.selectionChange.emit(this.selectItems);
  }

  addOption(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;

    if ((value || '').trim()) {
      this.selectItems.push(value.trim());
      this.onChange(this.selectItems);
      this.selectionChange.emit(this.selectItems);
    }

    if (input) {
      input.value = '';
    }
  }

  removeOption(option: string): void {
    const index = this.selectItems.indexOf(option);

    if (index >= 0) {
      this.selectItems.splice(index, 1);
      this.onChange(this.selectItems);
      this.selectionChange.emit(this.selectItems);
    }
  }

}
