<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        @if (success_msg != '') {
          <div class="alert alert-green">
            <span>{{ success_msg }}</span>
          </div>
        }
        <div class="card">
          <div class="card-header card-header-danger">
            <h3 class="card-title">Pending Orders List</h3>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead class="text-primary">
                  <tr>
                    <th>Order</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Curr</th>
                    <th>Ship</th>
                    <th>Subtot</th>
                    <th>DTM</th>
                    <th>Total</th>
                    <th>AdjShipFee</th>
                    <th>Coupon</th>
                    <th>CpnDisc</th>
                    <th>ZoneDisc</th>
                    <th>MinCartDisc</th>
                    <th>TotalDisc</th>
                    <th>Updated</th>
                  </tr>
                </thead>
                <tbody>
                  @for (row of list; track row) {
                    <tr>
                      <td>{{row.order_id}}</td>
                      <td>{{row.name}}</td>
                      <td>{{row.shipping_email}}</td>
                      <td>{{row.currency}}</td>
                      <td>{{row.shipping_total}}</td>
                      <td>{{row.sub_total}}</td>
                      <td>{{row.destination_transhipper_markup}}</td>
                      <td>{{row.total}}</td>
                      <td>{{row.adjusted_shipping_fee}}</td>
                      <td>{{row.offer_code}}</td>
                      <td>{{row.offer_code_discount}}</td>
                      <td>{{row.delivery_zone_discount}}</td>
                      <td>{{row.min_cart_value_delivery_discount_amount}}</td>
                      <td>{{row.total_discount}}</td>
                      <td>{{row.updated_on}}</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-header">
    <strong class="mr-auto">Success</strong>
  </div>
</div>
