<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <fh-gc-google-chart
        class="col-12 col-sm-12 col-md-12"
        #chart
        title="Daily Orders"
        [type]="chartType"
        [options]="dailyOrdersOptions"
        [data]="dailyOrdersData"
        [columns]="dailyOrderTableColumns"
        [width]="(chartWidth$ | async)!"
        [height]="700"
      >
      </fh-gc-google-chart>
      <fh-gc-google-chart
        class="col-12 col-sm-12 col-md-12"
        #chart
        title="Daily Sales"
        [type]="chartType"
        [options]="dailySalesOptions"
        [data]="dailySalesData"
        [columns]="dailySalesColumn"
        [width]="(chartWidth$ | async)!"
        [height]="700"
      >
      </fh-gc-google-chart>
    </div>

    <div class="row">
      <fh-gc-google-chart
        class="col-12 col-sm-12 col-md-12"
        #chart
        title="Monthly Orders"
        [type]="chartType"
        [data]="monthlyOrdersData"
        [columns]="monthlyOrdersColumn"
        [options]="monthlyOrdersOptions"
        [width]="(chartWidth$ | async)!"
        [height]="700"
      >
      </fh-gc-google-chart>
      <fh-gc-google-chart
        class="col-12 col-sm-12 col-md-12"
        #chart
        title="Monthly Sales"
        [type]="chartType"
        [data]="monthlySalesData"
        [columns]="monthlySalesColumn"
        [options]="monthlySalesOptions"
        [width]="(chartWidth$ | async)!"
        [height]="700"
      >
      </fh-gc-google-chart>
    </div>

    <div class="row">
      <fh-gc-google-chart
        class="col-12 col-sm-12 col-md-12"
        #chart
        title="Daily users"
        [type]="chartType"
        [data]="dailyUsersCount"
        [columns]="dailyUsersCountColumn"
        [options]="dailyUsersCountOptions"
        [width]="(chartWidth$ | async)!"
        [height]="700"
      >
      </fh-gc-google-chart>
      <fh-gc-google-chart
        class="col-12 col-sm-12 col-md-12"
        #chart
        title="Monthly users"
        [type]="chartType"
        [data]="monthlyUsersCount"
        [columns]="monthlyUsersCountColumn"
        [options]="monthlyUsersCountOptions"
        [width]="(chartWidth$ | async)!"
        [height]="700"
      >
      </fh-gc-google-chart>
    </div>

    <div class="row">
      <fh-gc-google-chart
        class="col-12 col-sm-12 col-md-12"
        #chart
        title="Daily Active Users (DAU)"
        [type]="chartType"
        [data]="dailyActiveUsers"
        [columns]="dailyActiveUsersColumns"
        [options]="dailyActiveUsersChartOption"
        [width]="(chartWidth$ | async)!"
        [height]="700"
      >
      </fh-gc-google-chart>
      <fh-gc-google-chart
        class="col-12 col-sm-12 col-md-12"
        #chart
        title="Monthly Active Users (MAU)"
        [type]="chartType"
        [data]="monthlyActiveUsers"
        [columns]="monthlyActiveUsersColumns"
        [options]="monthlyActiveUsersChartOption"
        [width]="(chartWidth$ | async)!"
        [height]="700"
      >
      </fh-gc-google-chart>
    </div>
  </div>
</div>
