<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        @if (success_msg !== '') {
        <div class="alert alert-green">
          <span>{{ success_msg }}</span>
        </div>
        }

        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">Orders Basic Details</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="text-primary">Order-ID</th>
                    <th># {{ id }}</th>
                    <th class="text-primary">Customer Name</th>
                    <th class="text-capitalize">{{ customer_name }}</th>
                  </tr>
                  <tr>
                    <th class="text-primary">Shipping Address</th>
                    <th>{{ shipping_address }}</th>
                    <th class="text-primary">Billing Address</th>
                    <th>{{ billing_address }}</th>
                  </tr>
                  <tr>
                    <th class="text-primary">Shipping Email</th>
                    <th>{{ shipping_email }}</th>
                    <th class="text-primary">Billing Email</th>
                    <th>{{ billing_email }}</th>
                  </tr>
                  <tr>
                    <th class="text-primary">Order Currency</th>
                    <th>{{ currency }}</th>
                    <th class="text-primary">Sub Total</th>
                    <th>{{ currency }} {{ sub_total | number : '1.2-2' }}</th>
                  </tr>
                  @if (offerCodeDetails) {
                  <tr>
                    <th class="text-primary">Coupon Code</th>
                    <th>{{ offerCodeDetails.offerCode }}</th>
                    <th class="text-primary">Coupon Code Discount</th>
                    <th>
                      {{ currency }}
                      {{
                        offerCodeDetails.offerDiscountAmount | number : '1.2-2'
                      }}
                    </th>
                  </tr>
                  }
                  <tr>
                    <th class="text-primary">Delivery Discount</th>
                    <th>
                      {{ currency }}
                      {{
                        deliveryDiscountAmount
                          ? deliveryDiscountAmount
                          : (0 | number : '1.2-2')
                      }}
                    </th>
                    <th class="text-primary">DTM</th>
                    <th>{{ currency }} {{ dtm || 0 | number : '1.2-2' }}</th>
                  </tr>
                  <tr>
                    <th class="text-primary">Shipping Total</th>
                    <th>
                      {{ currency }}
                      @if (shipping_total === adjustedShippingAmount) {
                      <span>{{ shipping_total | number : '1.2-2' }}</span>
                      } @if (shipping_total !== adjustedShippingAmount) {
                      <span
                        ><span class="strike">{{
                          shipping_total | number : '1.2-2'
                        }}</span
                        >&nbsp;<span>{{
                          adjustedShippingAmount | number : '1.2-2'
                        }}</span></span
                      >
                      }
                    </th>
                    <th class="text-primary">Total</th>
                    <th>{{ currency }} {{ total | number : '1.2-2' }}</th>
                  </tr>
                  <tr>
                    <th class="text-primary">Payment Status</th>
                    <th
                      class=" payment_status-{{
                        payment_status
                      }} text-capitalize"
                    >
                      {{ payment_status }}
                    </th>
                    <th class="text-primary">Order Status</th>
                    @if (whole_order_status === 'in_progress') {
                    <th>
                      <button
                        mat-raised-button
                        type="button"
                        (click)="order_status('confirmed')"
                        [disabled]="button_disabled"
                        class="btn btn-success"
                      >
                        Confirm</button
                      >&nbsp;&nbsp;
                      <button
                        mat-raised-button
                        type="button"
                        (click)="cancelShowPopup()"
                        [disabled]="button_disabled"
                        class="btn btn-warning"
                      >
                        Cancel
                      </button>
                      @if (error_msg !== '') {
                      <span style="color: red; font-size: 14px"
                        >&nbsp;{{ error_msg }}</span
                      >
                      }
                    </th>
                    } @if (whole_order_status !== 'in_progress') {
                    <th
                      class=" whole_order_status-{{
                        whole_order_status
                      }} text-capitalize"
                    >
                      {{ whole_order_status }} &nbsp;&nbsp; @if
                      (whole_order_status === 'cancelled') {
                      <span
                        style="color: blue; cursor: pointer"
                        (click)="cancelShowPopup()"
                        >show reason</span
                      >
                      }
                    </th>
                    }
                  </tr>
                  <tr>
                    <th class="text-primary">Order On</th>
                    <th>{{ created_on | date : 'medium' }}</th>
                    <th class="text-primary">Update On</th>
                    <th>{{ updated_on | date : 'medium' }}</th>
                  </tr>
                  <tr>
                    <th class="text-primary">Remarks</th>
                    <th>{{ remarks }}</th>
                    <th class="text-primary">Order Status Remarks</th>
                    <th>{{ orderRemarks }}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">Orders Details (Product List)</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead class="text-primary">
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Img</th>
                    <th>Total</th>
                    <th>Ship</th>
                    <th>DTM</th>
                    <th>Opt Name/OrigPrc/WithDTM</th>
                    <th>Qty</th>
                    <th>Vendor</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  @for (row of list; track row) {
                  <tr>
                    <td>{{ row.product_id }}</td>
                    <td class="text-capitalize">{{ row.product_name }}</td>
                    <td>
                      @if (row.product_image) {
                      <img
                        src="{{ row.product_image }}"
                        alt="Product Image"
                        width="80"
                        height="80"
                      />
                      }
                    </td>
                    <td>
                      {{ currency }}
                      {{ row.productTotalPrice | number : '1.2-2' }}
                    </td>
                    <td>
                      {{ currency }} {{ row.delivery_price | number : '1.2-2' }}
                    </td>
                    <td>
                      {{ currency }}
                      {{
                        row.totalDestinationTranshipperMarkup || 0
                          | number : '1.2-2'
                      }}
                    </td>
                    <td>
                      {{ row.addon_name }} / {{ currency }}
                      {{ row.price | number : '1.2-2' }} /
                      {{ row.final_price | number : '1.2-2' }}
                    </td>
                    <td>{{ row.quantity }}</td>
                    <td class="text-capitalize">{{ row.vendor_name }}</td>
                    <td>{{ row.delivery_remarks }}</td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

@if (cancel_popup === 'show') {
<dialog
  class="{{ cancel_popup }}"
  id="cancelDescriptionModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        @if (whole_order_status === 'in_progress') {
        <h5 class="modal-title" id="exampleModalLabel">
          Are you sure ? Cancel this order.
        </h5>
        } @if (whole_order_status === 'cancelled') {
        <h5 class="modal-title" id="exampleModalLabel">
          Order cancellation reason
        </h5>
        }
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="cancelClosePopup()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      @if (whole_order_status === 'in_progress') {
      <div class="modal-body">
        <div class="form-group">
          <textarea
            class="form-control"
            placeholder="Cancellation Reason"
            [(ngModel)]="cancellation_rason"
          ></textarea>
          <span class="" style="color: red; font-size: 12px">{{
            cancellation_error_msg
          }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-end pt-3">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            (click)="cancelClosePopup()"
          >
            No
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="order_status('cancelled')"
          >
            Yes
          </button>
        </div>
      </div>
      } @if (whole_order_status === 'cancelled') {
      <div class="modal-body">
        <p>{{ cancellation_rason }}</p>
      </div>
      }
    </div>
  </div>
</dialog>
}
