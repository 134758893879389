<div>
  @if (settings) {
    <fh-smart-table
      [settings]="settings"
      [source]="source"
      (editConfirm)="updateRecord($event)"
      >
    </fh-smart-table>
  }
</div>
