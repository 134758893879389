<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 m-auto">
      <div class="card">
        <div class="card-header card-header-danger">
          <h4 class="card-title">Category Input</h4>
        </div>
        <div class="card-body">
          <form [formGroup]="categoryForm">
            <div class="row">
              <mat-form-field class="col-md-12">
                <input
                  matInput
                  placeholder="Category Name"
                  formControlName="category_name"
                  required
                  />
                @if (isSubmitted && category_name?.['invalid']) {
                  <mat-hint>
                    <span class="text-danger"
                      ><img
                      src="../../../assets/images/info-icon-red.svg"
                      alt=""
                      class="info"
                      />This field is required.</span
                      >
                    </mat-hint>
                  }
                </mat-form-field>
              </div>

              @if (selectedCatId == 0) {
                <div class="row">
                  <mat-form-field class="col-md-12">
                    <mat-label>Category List</mat-label>
                    <mat-select formControlName="parent_id">
                      @for (row of categoryList; track row) {
                        <mat-option [value]="row.id">
                          {{ row.category_name }}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
              }

              <div class="row">
                <mat-form-field class="col-md-12">
                  <mat-label>Is Featured</mat-label>
                  <mat-select formControlName="is_featured">
                    <mat-option value="No">No</mat-option>
                    <mat-option value="Yes">Yes</mat-option>
                  </mat-select>
                  @if (is_featured_err_msg) {
                    <mat-hint>
                      @if (is_featured_err_msg) {
                        <span class="text-danger"
                          ><img
                          src="../../../assets/images/info-icon-red.svg"
                          alt=""
                          class="info"
                          />{{ is_featured_err_msg }}</span
                          >
                        }
                      </mat-hint>
                    }
                  </mat-form-field>
                </div>
                <div class="row">
                  <div class="col-md-12 upload-img">
                    @if (category_img_url) {
                      <img
                        class="img"
                        [src]="category_img_url"
                        alt="category"
                        />
                    }
                  </div>
                  <div class="col-md-12">
                    <button
                      class="btn btn-danger"
                      (click)="fileInput.click()"
                      type="button"
                      >
                      <span>Upload Image</span>
                      <input
                        #fileInput
                        type="file"
                        (change)="onFileInput($event)"
                        />
                    </button>
                  </div>
                </div>

                <span class="text-danger">{{ error }}</span>
                <span class="text-success">{{ success }}</span>
                <button
                  mat-raised-button
                  type="submit"
                  [disabled]="button_disabled"
                  class="btn btn-danger pull-right"
                  (click)="save()"
                  >
                  SAVE
                </button>
                <div class="clearfix"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
