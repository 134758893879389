import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IApiResponse } from '@models/api-response.model';
import { RequestService } from '@services/request/request.service';


@Component({
  selector: 'fh-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  standalone: true,
  imports: [DatePipe, DecimalPipe],
})
export class UserDetailsComponent implements OnInit {
  id!: number;
  success_msg = '';
  name = 'User';
  email = '';
  phone_no!: number;
  address = '';
  postalCode!: number;
  access!: string;
  created_on = '';
  updated_on = '';
  lastAccess = '';
  quizResult = {
    experienceLevel: '',
    aquariumBestDescription: '',
    fishType: '',
    tankCapacity: '',
  };
  
  productList!: ItemsEntityOrListOfItemsEntity[];
  priceInfo!: IPriceInfo;
  totalItems!: number;
  offerCodeDetails: any;

  constructor(private route: ActivatedRoute, private auth: RequestService) {}

  ngOnInit(): void {
    this.id = parseInt(this.route.snapshot.paramMap.get('id')!, 10);
    this.fetchUser();
    this.fetchCart();
  }
  fetchUser() {
    this.auth
      .get<IApiResponse>('/api/v1/user_details?id=' + this.id)
      .subscribe((res) => {
        if (res.result_code == 1) {
          this.name = res.data.first_name +  ' ' + res.data.last_name;
          this.email = res.data.email;
          this.phone_no = res.data.phone_num;
          this.address = res.data.address;
          this.postalCode = res.data.postal_code;
          this.created_on = res.data.created_on;
          this.updated_on = res.data.updated_on;
          if (res.data.quiz_result) {
            this.quizResult = res.data.quiz_result;
          }
        }
      });
  }

  fetchCart() {
    this.auth
      .get<IUserCartApiResponse>('/api/v1/get-user-cart?id=' + this.id)
      .subscribe((res) => {
        if (res.result_code == 1) {
          this.priceInfo = res.data.priceInfo;
          this.totalItems = res.data.totalItems;
          this.productList = res.data.listOfItems!;
        }
      });
  }
}

export interface IUserQuizResponse {
  experienceLevel: string;
  aquariumBestDescription: string;
  fishType: string;
  tankCapacity: string;
}
export interface IUserCartApiResponse {
  status: boolean;
  result_code: number;
  message: string;
  data: IUserCartApiData;
}
export interface IUserCartApiData {
  shoppingCart: ShoppingCart;
  priceInfo: IPriceInfo;
  totalItems: number;
  listOfItems?: ItemsEntityOrListOfItemsEntity[];
}
export interface ShoppingCart {
  8: 8;
}
export interface ItemsEntityOrListOfItemsEntity {
  id: number;
  cart_id: number;
  product_id: number;
  final_mrp: number;
  quantity: number;
  option_id: number;
  final_price: number;
  delivery_type: string;
  delivery_price: number;
  vendor_id: number;
  qty: number;
  product_image: string;
  product_name: string;
  addon_name: string;
  price_symbol: string;
  vendor_name: string;
  subtotal: number;
}
export interface IPriceInfo {
  total: number;
  subtotal: number;
  shipping: number;
  discount: number;
  offerCode?: null;
}
