export const merchantDeliveryLogicTableColumnsConfig = {
  merchantId: {
    title: 'Merchant Id',
    filter: false,
    editable: false,
  },
  merchantName: {
    title: 'Merchant Name',
    filter: false,
    editable: false,
  },
  deliveryText: {
    title: 'Delivery Text',
    filter: false,
    editor: {
      type: 'textarea',
    },
  },
};
