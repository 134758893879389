import { ProductControls } from '@components/product-input/product-input.component';

const commonControls: ProductControls[] = [
  ProductControls.ProductName,
  ProductControls.Qty,
  ProductControls.IsPromoted,
  ProductControls.Tags,
  ProductControls.OriginCountryId,
  ProductControls.DestinationCountryId,
  ProductControls.VendorId,
  ProductControls.Status,
  ProductControls.MoreDescription,
  ProductControls.IsFeatured,
  ProductControls.IsExclusive,
  ProductControls.AvailableInBuildAquarium,
  ProductControls.CategoryId,
  ProductControls.SubcategoryId,
  ProductControls.ProductMedia,
  ProductControls.ProductOptions,
  ProductControls.DeliveryStandard,
  ProductControls.DestinationTranshipperMarkup,
  ProductControls.DisplayPrice,
];
export const livestockInputControls: ProductControls[] = [
  ...commonControls,
  ProductControls.MinSize,
  ProductControls.MaxSize,
  ProductControls.SubsubcategoryId,
  ProductControls.IsSeasonal,
  ProductControls.ProductExpiry,
];

export const tankInputControls: ProductControls[] = [
  ...commonControls,
  ProductControls.CapacityInGallons,
  ProductControls.TankLength,
  ProductControls.TankBreadth,
  ProductControls.TankHeight,
];

export const otherProductInputControls: ProductControls[] = [
  ...commonControls,
];
