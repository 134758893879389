import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import {
    LocalDataSource,
    Ng2SmartTableModule,
} from '../../../libraries-forks/ng2-smart-table';
import {
    IApiResponse,
    IMerchantDeliveryLogicApiData,
    IMerchantDeliveryLogicList,
    IMerchantDeliveryLogicListApiResponse,
} from '../../models';
import { ApiMessageService, RequestService } from '../../services';
import { sleepUtil } from '../../utils';
import { merchantDeliveryLogicTableColumnsConfig } from './merchant-delivery-logic-table-columns.config';

import { FlexLayoutModule } from '@ngbracket/ngx-layout';
@Component({
  selector: 'fh-merchant-delivery-logic-page',
  templateUrl: './merchant-delivery-logic-page.component.html',
  styleUrls: ['./merchant-delivery-logic-page.component.scss'],
  standalone: true,
  imports: [Ng2SmartTableModule, FlexLayoutModule],
})
export class MerchantDeliveryLogicPageComponent implements OnInit {
  readonly RECORDS_PER_PAGE = 50;
  activePage = 1;
  settings: any;
  source: LocalDataSource = new LocalDataSource();

  constructor(
    private requestService: RequestService,
    private apiMessageService: ApiMessageService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getMerchantList();
  }
  getMerchantList() {
    this.requestService
      .get<IMerchantDeliveryLogicListApiResponse>(
        `/api/v1/merchant-delivery-logic/get-list?pagerLimit=${this.RECORDS_PER_PAGE}&activePage=${this.activePage}`
      )
      .subscribe((apiRes: IMerchantDeliveryLogicListApiResponse) => {
        this.changeSettingsForTable(apiRes.data);
      });
  }

  async changeSettingsForTable(data: IMerchantDeliveryLogicApiData) {
    const tableColumns = {
      merchantId: merchantDeliveryLogicTableColumnsConfig.merchantId,
      merchantName: merchantDeliveryLogicTableColumnsConfig.merchantName,
      deliveryText: merchantDeliveryLogicTableColumnsConfig.deliveryText,
    };

    const tempSettings = {
      actions: { add: false, edit: true, delete: false },
      edit: {
        editButtonContent: '<span class="material-icons">edit</span>',
        saveButtonContent: '<span class="material-icons">done</span>',
        cancelButtonContent: '<span class="material-icons">close</span>',
        confirmSave: true,
      },
      columns: tableColumns,
      pager: {
        display: true,
        perPage: this.RECORDS_PER_PAGE,
      },
    };
    await sleepUtil(100);

    this.settings = Object.assign({}, tempSettings);
    this.source.load(data.merchantList);
    this.cdr.detectChanges();
  }

  updateRecord(event: { confirm: any; newData: IMerchantDeliveryLogicList }) {
    this.requestService
      .post<IApiResponse>('/api/v1/merchant-delivery-logic/update-row', {
        merchantId: event.newData.merchantId,
        deliveryText: event.newData.deliveryText,
      })
      .subscribe((apiRes) => {
        this.apiMessageService.manageAlertMessage({
          apiRes,
        });
        if (apiRes.result_code === 1) {
          event.confirm.resolve(event.newData);
        }
      });
  }
}
