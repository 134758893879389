<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        @if (success_msg!=='') {
        <div class="alert alert-green">
          <span>{{ success_msg }}</span>
        </div>
        }
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">Category List</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead class="text-primary">
                  <tr>
                    <th>ID</th>
                    <th>Category Name</th>
                    <th>Image</th>
                    <th>Parent Category Name</th>
                    <th>Category-Type</th>
                    <th>Is Featured</th>
                    <th>Action</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  @for (row of list; track row) {
                  <tr>
                    <td>{{ row.id }}</td>
                    <td>{{ row.category_name }}</td>
                    <td>
                      @if (!!row.category_img) {
                      <img
                        src="{{ row.category_img }}"
                        alt="category-img"
                        width="80px;"
                        height="80px;"
                      />
                      }
                    </td>
                    <td>{{ row.parent_name }}</td>
                    <td>
                      @if (row.parent_id === 0) {
                      <span> Main </span>
                      } @if (row.parent_id !== 0) {
                      <span> Sub </span>
                      }
                    </td>
                    <td>{{ row.is_featured }}</td>
                    <td>
                      <a [routerLink]="['/category_input/' + row.id]">
                        <span
                          _ngcontent-gxv-c19=""
                          class="material-icons icon-image-preview"
                          >visibility</span
                        > </a
                      >&nbsp; @if (row.parent_id !== 0) {
                      <a [routerLink]="['/category_input/' + row.id]">
                        <span
                          _ngcontent-gxv-c19=""
                          class="material-icons icon-image-preview"
                          >delete</span
                        >
                      </a>
                      }
                    </td>
                    @if (row.status.trim() === 'active') {
                    <td style="text-transform: capitalize; color: blue">
                      {{ row.status }}
                    </td>
                    } @if (row.status.trim() !== 'active') {
                    <td style="text-transform: capitalize; color: red">
                      {{ row.status }}
                    </td>
                    }
                  </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
