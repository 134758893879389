import { Component, OnInit } from '@angular/core';
import {
    ReactiveFormsModule,
    UntypedFormBuilder,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';



import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { environment } from '@environments/environment';
import { RequestService } from '@services/index';


@Component({
  selector: 'fh-vendor-detail',
  templateUrl: './category-input.component.html',
  styleUrls: ['./category-input.component.css'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule
],
})
export class CategoryInputComponent implements OnInit {
  checked = false;
  error = '';
  success = '';
  error2 = '';
  success2 = '';
  errorUpload = '';
  successUpload = '';

  isSubmitted = false;
  categoryList:any[] = [];
  selectedCatId = 0;
  button_disabled = false;

  category_img_url: any;
  image_err_msg = '';
  image_ext = '';
  image_submit = false;
  app_url = environment.backendURL;

  is_featured_err_msg = '';

  categoryForm = this.formBuilder.group({
    category_name: ['', Validators.required],
    parent_id: [''],
    image: [''],
    is_featured: ['No'],
  });
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private auth: RequestService
  ) {
    this.selectedCatId = parseInt(this.route.snapshot.paramMap.get('id')!, 10);
  }

  get category_name() {
    return this.categoryForm.get('category_name');
  }

  get image() {
    return this.categoryForm.get('image');
  }

  get is_featured() {
    return this.categoryForm.get('is_featured');
  }
  ngOnInit(): void {
    this.getCategories();
    if (this.selectedCatId != 0) {
      this.auth
        .get('/api/v1/edit_category?id=' + this.selectedCatId)
        .subscribe((res: any) => {
          if (res.result_code === 1) {
            this.categoryForm.controls['parent_id'].setValue(
              parseInt(res.data.parent_id)
            );
            this.categoryForm.controls['category_name'].setValue(
              res.data.category_name
            );

            if (!!res.data.category_img) {
              this.image_submit = true;
              this.category_img_url = res.data.category_img;
              this.image_err_msg = '';
            }

            if (!!res.data.is_featured) {
              this.categoryForm.controls['is_featured'].setValue(
                res.data.is_featured
              );
              this.is_featured_err_msg = '';
            }
          } else {
            localStorage.clear();
            this.router.navigate(['/categories']);
          }
        });
    }
  }

  save() {
    this.isSubmitted = true;
    if (!this.categoryForm.valid) {
      return;
    }

    if (
      (!this.category_img_url || this.image_submit == false) &&
      this.categoryForm.controls['is_featured'].value == 'Yes'
    ) {
      this.image_err_msg = 'Please choose a image';

      return;
    } else this.image_err_msg = '';

    this.button_disabled = true;

    const data = {
      category_name: this.categoryForm.controls['category_name'].value,
      image: this.categoryForm.controls['image'].value,
      is_featured: this.categoryForm.controls['is_featured'].value,
      parent_id: this.categoryForm.controls['parent_id'].value,
      image_ext: this.image_ext,
      category_id: this.selectedCatId,
    };
    this.auth
      .post('/api/v1/category_input', data)
      .subscribe((res: any) => {
        if (res.result_code === 1) {
          this.router.navigate(['/categories'], {
            state: { success_msg: 'Record saved successfully.' },
          });
        } else {
          this.error = res.message;
          this.button_disabled = false;
        }
      });
  }

  getCategories() {
    this.auth
      .get('/api/v1/get_parent_categories')
      .subscribe((res: any) => {
        if (res.status === true) {
          this.categoryList = res.data;
        }
      });
  }

  onFileInput(event: any) {
    const reader = new FileReader();
    const len = event.target.files[0].name.split('.').length;
    const ext = event.target.files[0].name.split('.')[len - 1];
    this.image_ext = ext;
    if (
      ext === 'png' ||
      ext === 'PNG' ||
      ext === 'jpg' ||
      ext === 'JPG' ||
      ext === 'jpeg' ||
      ext === 'JPEG'
    ) {
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.categoryForm.controls['image'].setValue(reader.result);
        this.category_img_url = reader.result;
        this.image_submit = true;
        this.image_err_msg = '';
      };
    } else {
      this.image_err_msg = 'File format is not valid.';
    }
  }
}
