<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-secondary black mt-1">
            <mat-form-field appearance="fill">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input
                  matStartDate
                  formControlName="start"
                  placeholder="Start date"
                />
                <input
                  matEndDate
                  formControlName="end"
                  placeholder="End date"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>

              @if (range.controls['start'].hasError('matStartDateInvalid')) {
              <mat-error>Invalid start date</mat-error>
              } @if (range.controls['end'].hasError('matEndDateInvalid')) {
              <mat-error>Invalid end date</mat-error>
              }
            </mat-form-field>
            <h4>
              <a
                (click)="clearDatePicker()"
                (keyup.enter)="clearDatePicker()"
                (keydown.enter)="clearDatePicker()"
                tabindex="0"
                class="black mt-1 mb-3"
                target="_black"
                mat-button
                >Clear Date Picker
                <mat-icon aria-hidden="false" aria-label="Example home icon"
                  >cancel</mat-icon
                ></a
              >
              <a
                (click)="downloadCSV()"
                (keyup.enter)="downloadCSV()"
                (keydown.enter)="downloadCSV()"
                tabindex="0"
                class="black mt-1 mb-3"
                target="_black"
                mat-button
                >Download CSV
                <mat-icon aria-hidden="false" aria-label="Example home icon"
                  >get_app</mat-icon
                ></a
              >
            </h4>
          </div>

          <div class="merchant-tab-group">
            <div class="container">
              <h4 class="col-12">Merchants</h4>
              <span>
                <mat-button-toggle-group
                  name="fontStyle"
                  aria-label="Font Style"
                  (change)="onMerchantSelection($event.value)"
                >
                  <mat-button-toggle value="0">All</mat-button-toggle>
                  @for (vendor of vendors; track vendor) {
                  <mat-button-toggle [value]="vendor.vendorId">{{
                    vendor.vendorName
                  }}</mat-button-toggle>
                  }
                </mat-button-toggle-group>
              </span>
            </div>
          </div>

          <div class="card-header card-header-danger">
            <h4 class="card-title">Merchants Orders List</h4>
          </div>

          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead class="text-primary">
                  <tr>
                    <th>OrderID</th>
                    <th>Customer Name</th>
                    <th>Merchant</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Livestock & Plants</th>
                    <th>Others</th>
                    <th>Total</th>
                    <th>DTM</th>
                    <th>Shipping</th>
                    <th>Order Status Remarks</th>
                    <th>Remarks</th>
                    <th>Delivery Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  @for (row of list; track row) {
                  <tr>
                    <td>
                      <a
                        class="d-flex"
                        [routerLink]="['/order_details/' + row.order_id]"
                      >
                        <span
                          _ngcontent-gxv-c19=""
                          class="material-icons icon-image-preview"
                          >visibility</span
                        >
                        {{ row.order_id }}
                      </a>
                    </td>
                    <td class="text-capitalize">{{ row.customer }}</td>
                    <td>{{ row.merchant }}</td>
                    <td>{{ row.ordered_on | date }}</td>
                    @if (row.status !== 'in_progress') {
                    <td
                      class="text-capitalize whole_order_status-{{
                        row.status
                      }}"
                    >
                      {{ row.status }}
                    </td>
                    } @if (row.status === 'in_progress') {
                    <td
                      class="text-capitalize whole_order_status-{{
                        row.status
                      }}"
                    >
                      In Progress
                    </td>
                    }
                    <td>{{ row.plantsAndLivestock | number : '1.2-2' }}</td>
                    <td>{{ row.others | number : '1.2-2' }}</td>
                    <td>{{ row.total | number : '1.2-2' }}</td>
                    <td>{{ row.totalDtm || 0 | number : '1.2-2' }}</td>
                    <td>
                      {{ row.shipping_total | number : '1.2-2' }}
                    </td>
                    <td>{{ row.order_remarks }}</td>
                    <td>{{ row.remarks }}</td>
                    @if ( ( row.order_id === remarks.orderId && row.vendor_id
                    === remarks.vendorId ) ) {
                    <td>
                      @if (row.status === 'confirmed') {
                      <textarea
                        type="text"
                        class="form-control"
                        [value]="row.delivery_remarks"
                        (keyup)="onChange(row.order_id, row.vendor_id, $event)"
                        (keydown)="
                          onChange(row.order_id, row.vendor_id, $event)
                        "
                        cols="10"
                        rows="4"
                      ></textarea>
                      <span>
                        <mat-icon
                          class="icon"
                          (click)="submitRemark(row.order_id, row.vendor_id)"
                          >check</mat-icon
                        >
                        <mat-icon class="icon" (click)="clearRemark()"
                          >clear</mat-icon
                        >
                      </span>
                      }
                    </td>
                    } @else {
                    <td
                      (click)="
                        onChange(
                          row.order_id,
                          row.vendor_id
                        )
                      "
                    >
                      {{ row.delivery_remarks }}
                    </td>
                    }
                  </tr>
                  }
                </tbody>
              </table>
              <mat-paginator
                [length]="this.total"
                pageSize="40"
                (page)="onPageChange($event)"
                aria-label="Select page"
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-header">
    <strong class="mr-auto">Success</strong>
  </div>
</div>
