<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        @if (!!success_msg) {
        <div class="alert alert-green">
          <span>{{ success_msg }}</span>
        </div>
        }
        <div class="flex gap-4">
          <input
            matInput
            #searchInput
            placeholder="Search Vendor"
            [value]="searchText"
            (keyup.enter)="searchVendors(searchInput.value)"
          />
          <button
            mat-button
            matSuffix
            (click)="searchVendors(searchInput.value)"
          >
            <mat-icon>search</mat-icon>
          </button>
          @if (searchInput.value !== '') {
            <button mat-button (click)="searchVendors('')">Clear</button>
          }
        </div>
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">Vendor List</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead class="text-primary">
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Address</th>
                    <th>Zones</th>
                    <th>Star Seller</th>

                    <th>Action</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  @for (row of list; track row) {
                  <tr>
                    <td>{{ row.id }}</td>
                    <td class="text-capitalize">
                      {{ row.first_name }} {{ row.last_name }}
                    </td>
                    <td>{{ row.email }}</td>
                    <td>{{ row.phone_num }}</td>
                    <td>
                      {{ row.address_line1 }} {{ row.city }} {{ row.state }}
                    </td>
                    <td>{{ row.zone }}</td>
                    <td>{{ row.is_star_seller }}</td>
                    <td>
                      <a [routerLink]="['/vendor_input/' + row.id]">
                        <span
                          _ngcontent-gxv-c19=""
                          class="material-icons icon-image-preview"
                          >visibility</span
                        > </a
                      >&nbsp;
                      <a [routerLink]="['/vendor_input/' + row.id]">
                        <span
                          _ngcontent-gxv-c19=""
                          class="material-icons icon-image-preview"
                          >delete</span
                        >
                      </a>
                    </td>
                    @if (row.status?.trim() === 'active') {
                    <td style="text-transform: capitalize; color: blue">
                      {{ row.status }}
                    </td>
                    } @else {
                    <td style="text-transform: capitalize; color: red">
                      {{ row.status }}
                    </td>

                    }
                  </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <mat-paginator
              [length]="totalRecords"
              [pageSize]="pageSize"
              [pageIndex]="pageIndex"
              (page)="onPageChange($event)"
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
