import { DatePipe, DecimalPipe, NgClass } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { environment } from '@environments/environment';
import {
  ICategoryList,
  ICategoryListEntity,
  IProductListApiResponse,
  IProductResponseDto,
} from '@models/product-list.model';
import { ExtendedModule, FlexModule } from '@ngbracket/ngx-layout';
import { RequestService } from '@services/index';
import { ProductService } from '@services/product/product.service';
import { map, switchMap } from 'rxjs';
@Component({
  selector: 'fh-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatTableModule,
    MatSort,
    NgClass,
    DecimalPipe,
    DatePipe,
    RouterLink,
    MatPaginatorModule,
    FlexModule,
    ExtendedModule,
    MatExpansionModule,
    RouterLink,
    MatIcon,
  ],
})
export class ProductListPageComponent implements OnInit, AfterViewInit {
  access = localStorage.getItem('aqua_access');
  user_id = localStorage.getItem('aqua_userid');
  productListByCategoryId: ICategoryListEntity[] = [];

  success_msg = '';
  error_msg = '';
  app_url = environment.backendURL;
  searchText = '';
  highlightedRow!: number; // It will hold id of selected row
  productList: IProductResponseDto[] = [];
  dataSource = new MatTableDataSource<IProductResponseDto>(this.productList);
  pageIndex: string | number = 0;
  pageSize = 0;
  orderField = '';
  order = '';
  category_id!: string | number;
  total!: number;
  totalProducts = 0;
  merchantId = 0;
  @ViewChild(MatSort, { static: false })
  sort!: MatSort;
  totalProductsAllCategories!: number | string;
  @ViewChild('input', { static: false })
  searchWords!: ElementRef;
  displayedColumns: string[] = [
    'id',
    'image',
    'name',
    'mrp',
    'price',
    'quantity',
    'deliveryCharges',
    'subcategory',
    'productExpiry',
    'merchant',
    'action',
    'status',
  ];

  @ViewChild(MatPaginator, { static: false })
  paginator!: MatPaginator;
  merchantList: { quantity: number; vendor_id: number; name: string }[] = [];

  matExpansionTitle = {
    expanded: false,
    merchant: {
      name: 'All merchants',
      quantity: 0,
    },
    category: {
      name: 'All categories',
      quantity: 0,
    },
  };
  paramsAndQueryParams = this.activatedRoute.params.pipe(
    switchMap((params) => {
      return this.activatedRoute.queryParams.pipe(
        map((queryParams) => {
          return { params, queryParams };
        })
      );
    })
  );
  constructor(
    private auth: RequestService,
    private cdr: ChangeDetectorRef,
    private productService: ProductService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.fetchVendors();
    this.paramsAndQueryParams.subscribe((data) => {
      this.matExpansionTitle.expanded = false;
      this.merchantId = data.params['merchantId'] || 0;
      this.category_id = data.params['categoryId'] || 'all';
      this.pageIndex = data.queryParams['pageIndex'] || 0;
      this.pageSize = +(data.queryParams['pageSize'] || 10);
      this.searchText = data.queryParams['search'] || '';
      this.fetchItemCountForCategories();
      this.fetchProducts();
      this.dataSource.sort = this.sort;
      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  fetchVendors() {
    this.auth.get('/api/v1/productList/fetchVendors').subscribe((res: any) => {
      this.merchantList = res.data.vendors?.map(
        (value: { no_of_products: any; vendor_id: any; name: any }) => ({
          quantity: value.no_of_products,
          vendor_id: value.vendor_id,
          name: value.name,
        })
      );
      this.totalProducts = res.data.count;
      this.setExpansionPanelTitle();
    });
  }

  fetchItemCountForCategories() {
    this.auth
      .get<ICategoryList>(
        `/api/v1/count_category_list?vendorId=${this.merchantId}`
      )
      .subscribe((res: ICategoryList) => {
        this.totalProducts = res.data.count;
        this.totalProductsAllCategories = res.data.count;
        this.productListByCategoryId = [];
        res.data.list?.forEach((value) => {
          this.productListByCategoryId.push({
            id: value.id,
            category_name: value.category_name,
            quantity: value.quantity,
          });
        });
        this.setExpansionPanelTitle();
      });
  }

  fetchProducts(options?: { expired: boolean }) {
    const { expired } = options || {};
    const data = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      orderField: expired ? 'expires_at' : this.orderField,
      order: expired ? 'asc' : this.order,
      search: this.searchText,
      category: this.category_id,
      merchant: this.merchantId,
      expired,
    };
    console.log('data', data);
    const api = `/api/v1/product_list`;
    this.auth
      .post<IProductListApiResponse>(api, data)
      .subscribe((res: IProductListApiResponse) => {
        if (res.result_code !== 1) {
          return;
        }

        if (!res.data.list?.length) {
          this.productList = [];
          this.total = res.data.count;
          this.dataSource = new MatTableDataSource<IProductResponseDto>(
            this.productList
          );
          this.cdr.detectChanges();
          return;
        }
        this.productList = res.data.list
          .filter((i: any) => !!i)
          .map((d: IProductResponseDto) => ({
            ...d,
            status: d.status?.trim(),
          }));
        this.success_msg = '';
        this.total = res.data.count;
        this.dataSource = new MatTableDataSource<IProductResponseDto>(
          this.productList
        );
        this.setExpansionPanelTitle();
        this.cdr.detectChanges();
      });
  }

  searchProducts(searchText: string) {
    this.pageIndex = 0;
    this.router.navigateByUrl(
      `/products/${this.merchantId}/${this.category_id}?pageIndex=${
        this.pageIndex
      }&pageSize=${this.pageSize}&search=${
        searchText?.trim()?.toLowerCase() || ''
      }`
    );
  }

  // this func highlights a row
  highlight(id: number) {
    this.highlightedRow = id;
  }
  // this function deletes a row
  delete(id: number) {
    const product_id = id;
    const user_id = localStorage.getItem('aqua_userid');
    if (confirm('Are you sure ?')) {
      this.auth
        .delete(
          `/api/v1/vendor/vendor_product_list?vendor_id=${user_id}&prod_id=${product_id}`
        )
        .subscribe((res: any) => {
          if (res.result_code) {
            this.success_msg = 'Deleted successfully';
            this.productList.splice(
              this.productList.findIndex(
                (val: { id: number }) => val.id === product_id
              ),
              1
            );
            this.dataSource = new MatTableDataSource<IProductResponseDto>(
              this.productList
            );
            this.success_msg = 'Deleted Successfully';
            this.cdr.detectChanges();
            setTimeout(() => {
              this.success_msg = '';
            }, 7000);
          }
        });
    }
  }
  resetProductExpiry(product: IProductResponseDto) {
    if (!confirm('This action will restart the expiry timer')) {
      return;
    }

    const newExpiry = this.productService.resetProductExpiry({
      expiresAt: product.expires_at!,
      expiryUpdatedAt: product.expiry_updated_at!,
    });

    this.auth
      .put(
        `/api/v1/product-expiry/reset?productId=${
          product.id
        }&newExpiry=${newExpiry.toISOString()}`
      )
      .subscribe((res: any) => {
        if (res.result_code) {
          this.success_msg = res.message;
          product.expires_at = newExpiry.toISOString();
          product.status = 'active';
        } else {
          this.error_msg = res.message;
        }

        setTimeout(() => {
          this.success_msg = '';
          this.error_msg = '';
        }, 4000);
      });
  }

  sortData(sort: Sort) {
    // API call goes here
    this.orderField = sort.active;
    this.order = sort.direction;
    this.success_msg = 'Loading...';
    this.fetchProducts();
  }
  onPageChange(event: any) {
    this.router.navigateByUrl(
      `/products/${this.merchantId}/${this.category_id}?pageIndex=${event.pageIndex}&pageSize=${event.pageSize}&search=${this.searchText}`
    );
  }

  setExpansionPanelTitle() {
    const matchedCategory =
      this.productListByCategoryId.find((v) => v.id === +this.category_id) ||
      ({} as any);

    const matchedMerchant =
      this.merchantList.find((v) => v.vendor_id === +this.merchantId) ||
      ({} as any);
    this.matExpansionTitle = {
      ...this.matExpansionTitle,
      merchant: {
        name: matchedMerchant?.name || 'All merchants',
        quantity: matchedMerchant?.quantity || this.totalProducts,
      },
      category: {
        name: matchedCategory?.category_name || 'All categories',
        quantity: +(
          matchedCategory?.quantity || this.totalProductsAllCategories
        ),
      },
    };
  }

  isProductExpired(expiryDate: string | number | Date) {
    return new Date(expiryDate) < new Date();
  }
}
