import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartType } from '@forks/angular-google-charts/types/chart-type';
import { IDashboardApiResponse } from '@models/dashboard-api.model';
import { AppStateService } from '@services/app-state/app-state.service';
import { RequestService } from '@services/request/request.service';
import { distinctUntilChanged, map } from 'rxjs';
import { GoogleChartsModule } from 'src/libraries-forks/angular-google-charts/google-charts.module';

@Component({
  selector: 'fh-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  standalone: true,
  imports: [GoogleChartsModule, AsyncPipe],
})
export class DashboardComponent implements OnInit {
  chartType =
    this.appStateService.deviceType$.value === 'mobile'
      ? ChartType.BarChart
      : ChartType.ColumnChart;
  chartWidth$ = this.appStateService.deviceType$.pipe(
    distinctUntilChanged(), // Ensure changes in device type trigger a new value
    map((deviceType) => {
      if (deviceType === 'mobile') {
        return this.appStateService.windowSize$.value?.innerWidth - 50;
      } else {
        return this.appStateService.windowSize$.value?.innerWidth;
      }
    })
  );
  dailyOrdersData: any[] = [];
  dailyOrdersOptions = this.getChartOptions({
    xAxisTitle: 'Date',
    yAxisTitle: 'Orders',
  });
  dailyOrderTableColumns = this.getColumns(['order_date', '#no of orders']);
  dailySalesData: any[] = [];
  dailySalesColumn = this.getColumns(['date', '#sales']);
  dailySalesOptions = this.getChartOptions({
    xAxisTitle: 'Date',
    yAxisTitle: 'Sales',
  });

  monthlyUsersCount: any[] = [];
  monthlySalesData: any[] = [];
  monthlyOrdersData: any[] = [];
  monthlySalesColumn: any[] = [
    { type: 'string', label: 'month' },
    { type: 'number', label: '#sales' },
    { type: 'number', role: 'annotation' },
  ];
  dailyUsersCount: any[] = [];
  dailyUsersCountColumn: any[] = [
    { type: 'string', label: 'date' },
    { type: 'number', label: '#no of users' },
    { type: 'number', role: 'annotation' },
  ];
  dailyUsersCountOptions = {
    hAxis: {
      title: 'Date',
    },
    vAxis: {
      title: 'No of users',
    },
    colors: ['#FF8040'],
    displayAnnotations: true,
  };
  monthlyUsersCountColumn = [
    { type: 'string', label: 'month' },
    { type: 'number', label: '#no of users' },
    { type: 'number', role: 'annotation' },
  ];

  monthlyOrdersColumn = [
    { type: 'string', label: 'month' },
    { type: 'number', label: '#no of orders' },
    { type: 'number', role: 'annotation' },
  ];
  monthlyOrdersOptions = {
    hAxis: {
      title: 'Month',
    },
    vAxis: {
      title: 'Orders',
    },
    colors: ['#0c55b8'],
    displayAnnotations: true,
  };
  monthlyUsersCountOptions = {
    hAxis: {
      title: 'Month',
    },
    vAxis: {
      title: 'No of users',
    },
    colors: ['#FF8040'],
    displayAnnotations: true,
  };
  monthlySalesOptions = this.getChartOptions({
    xAxisTitle: 'Month',
    yAxisTitle: 'Sales',
  });

  access: string = localStorage.getItem('aqua_access')!;
  monthlySalesAndOrders: any[] = [];
  dailySalesAndOrders: any[] = [];
  monthlyActiveUsers: any[] = [];

  monthlyActiveUsersChartOption = this.getChartOptions({
    xAxisTitle: 'Month',
    yAxisTitle: '#no of users',
  });
  monthlyActiveUsersColumns = this.getColumns(['month', '#number of users']);

  dailyActiveUsers: any[] = [];
  dailyActiveUsersChartOption = this.getChartOptions({
    xAxisTitle: 'Date',
    yAxisTitle: '#no of users',
  });
  dailyActiveUsersColumns = this.getColumns(['date', '#number of users']);
  constructor(
    private request: RequestService,
    public appStateService: AppStateService,
    private router: Router
  ) {}

  ngOnInit() {
    if (!localStorage.getItem('aqua_auth_token')) {
      this.router.navigate(['/login']);
    }
    this.request
      .get<IDashboardApiResponse>(`/api/v1/dashboard`)
      .subscribe((res) => {
        if (res.result_code === 1) {
          this.monthlySalesAndOrders = res.data.monthly;
          this.dailySalesAndOrders = res.data.daily;
          this.dailyUsersCount = res.data.dailyUsersCount.map(
            (thatDate: {
              joined_on: any;
              total_daily_users: string | number;
            }) => {
              return [
                thatDate.joined_on,
                +thatDate.total_daily_users,
                +thatDate.total_daily_users,
              ];
            }
          );
          this.monthlyUsersCount = res.data.monthlyUsersCount.map(
            (thatMonth: { month: any; monthly_daily_users: string }) => {
              return [
                thatMonth.month,
                parseInt(thatMonth.monthly_daily_users, 10),
                parseInt(thatMonth.monthly_daily_users, 10),
              ];
            }
          );
          this.dailyOrdersData = this.dailySalesAndOrders.map((thatDate) => {
            return [
              thatDate.created_on,
              parseInt(thatDate.orders, 10),
              parseInt(thatDate.orders, 10),
            ];
          });
          this.dailySalesData = this.dailySalesAndOrders.map((thatDate) => {
            return [thatDate.created_on, thatDate.sales, thatDate.sales];
          });
          this.monthlyOrdersData = this.monthlySalesAndOrders.map(
            (thatMonth) => {
              return [
                thatMonth.month,
                parseInt(thatMonth.orders, 10),
                parseInt(thatMonth.orders, 10),
              ];
            }
          );
          this.monthlySalesData = this.monthlySalesAndOrders.map(
            (thatMonth) => {
              return [thatMonth.month, thatMonth.sales, thatMonth.sales];
            }
          );

          this.monthlyActiveUsers = res.data.monthlyActiveUsers.map(
            (thatMonth: { month: any; userCount: string }) => {
              return [
                thatMonth.month,
                parseInt(thatMonth.userCount, 10),
                parseInt(thatMonth.userCount, 10),
              ];
            }
          );
          this.dailyActiveUsers = res.data.dailyActiveUsers.map(
            (thatMonth: { date: any; userCount: string }) => {
              return [
                thatMonth.date,
                parseInt(thatMonth.userCount, 10),
                parseInt(thatMonth.userCount, 10),
              ];
            }
          );
        }
      });
  }

  getChartOptions({ xAxisTitle, yAxisTitle }: any) {
    return {
      hAxis: {
        title: xAxisTitle,
      },
      vAxis: {
        title: yAxisTitle,
      },
      colors: ['#FF8040'],
      displayAnnotations: true,
    };
  }
  getColumns(arr: any[] = []) {
    return [
      { type: 'string', label: arr[0] },
      { type: 'number', label: arr[1] },
      { type: 'number', role: 'annotation' },
    ];
  }
}
