import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MerchantDeliveryLogicPageComponent } from './pages/merchant-delivery-logic-page/merchant-delivery-logic-page.component';

import { AuthGaurd } from '@guards/auth.gaurd';
import { LoginComponent } from '@pages/auth/login/login.component';
import { CategoryInputComponent } from '@pages/category/category-input/category-input.component';
import { CategoryListComponent } from '@pages/category/category-list/category-list.component';
import { MerchantOrdersComponent } from '@pages/merchant-orders/merchant-orders.component';
import { OfferListPageComponent } from '@pages/offer-list-page/offer-list-page.component';
import { OrderDetailsComponent } from '@pages/orders/order-details/order-details.component';
import { OrderListComponent } from '@pages/orders/order-list/order-list.component';
import { PendingOrderListPageComponent } from '@pages/orders/pending-order-list-page/pending-order-list-page.component';

import { RecommendationListComponent } from '@pages/recommendation/recommendation-list/recommendation-list.component';
import { UserDetailsComponent } from '@pages/user-details/user-details.component';
import { UserListComponent } from '@pages/user-list/user-list.component';
import { VendorDetailComponent } from '@pages/vendor/vendor-detail/vendor-detail.component';
import { VendorListComponent } from '@pages/vendor/vendor-list/vendor-list.component';

import { DashboardComponent } from '@pages/dashboard/dashboard.component';

import { ProductInputPageComponent } from '@pages/product/product-input/product-input-page.component';
import { ProductListPageComponent } from '@pages/product/product-list/product-list.component';
import { shortPath } from './app-routes.const';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },

  // static routes
  {
    path: 'dashboard',
    canActivate: [AuthGaurd],
    component: DashboardComponent,
  },

  { path: 'vendors', component: VendorListComponent },
  { path: 'vendor_input/:id', component: VendorDetailComponent },

  {
    path: 'categories',
    canActivate: [AuthGaurd],
    component: CategoryListComponent,
  },
  {
    path: 'category_input/:id',
    canActivate: [AuthGaurd],
    component: CategoryInputComponent,
  },

  {
    path: 'products',
    pathMatch: 'full',
    redirectTo: 'products/0/all',
  },

  {
    path: 'products/:merchantId',
    pathMatch: 'full',
    redirectTo: 'products/:merchantId/all',
  },
  {
    path: 'products/:merchantId/:categoryId',
    canActivate: [AuthGaurd],
    component: ProductListPageComponent,
  },
  {
    path: 'product_input/:id',
    canActivate: [AuthGaurd],
    component: ProductInputPageComponent,
  },

  { path: 'user_list', canActivate: [AuthGaurd], component: UserListComponent },
  { path: 'orders', canActivate: [AuthGaurd], component: OrderListComponent },
  {
    path: 'pending-order-list',
    canActivate: [AuthGaurd],
    component: PendingOrderListPageComponent,
  },

  {
    path: 'offer',
    canActivate: [AuthGaurd],
    component: OfferListPageComponent,
  },

  {
    path: 'order_details/:id',
    canActivate: [AuthGaurd],
    component: OrderDetailsComponent,
  },
  {
    path: 'recommendations',
    canActivate: [AuthGaurd],
    component: RecommendationListComponent,
  },
  {
    path: 'admin/merchantOrders',
    canActivate: [AuthGaurd],
    component: MerchantOrdersComponent,
  },
  {
    path: 'admin/user-details/:id',
    canActivate: [AuthGaurd],
    component: UserDetailsComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'auction',
    canActivate: [AuthGaurd],
    loadChildren: () =>
      import('./pages/auction-page/auction-page.module').then(
        (m) => m.AuctionPageModule
      ),
  },
  {
    path: 'campaign',
    canActivate: [AuthGaurd],
    loadChildren: () =>
      import('./pages/campaign/campaign.module').then((m) => m.CampaignModule),
  },

  {
    path: shortPath.merchantDeliveryLogic,
    canActivate: [AuthGaurd],
    loadComponent: () => MerchantDeliveryLogicPageComponent,
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
