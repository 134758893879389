import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Router } from '@angular/router';
import { ChipsMultiSelectComponent } from '@components/chips-multi-select/chips-multi-select.component';
import { RequestService } from '@services/index';

@Component({
  selector: 'fh-recommendation-list',
  templateUrl: './recommendation-list.component.html',
  styleUrls: ['./recommendation-list.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, ChipsMultiSelectComponent, ReactiveFormsModule],
})
export class RecommendationListComponent implements OnInit {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  list: any[] = [];
  constructor(private auth: RequestService, private router: Router) {}

  ngOnInit(): void {
    this.loadTags();
  }

  loadTags() {
    this.auth.get('/api/v1/get_recommendation').subscribe((res: any) => {
      if (res.status === true) {
        this.list = res.data.map((element: { key: any; tags: string }) => ({
          key: element.key,
          tags: element.tags?.split(','),
          isUpdated: false,
        }));
        console.log(this.list);
      } else {
        console.log(res);
      }
    });
  }

  saveTagForKey(i: number): void {
    this.list[i].isUpdated = true;
    const data = { key: this.list[i].key, tags: this.list[i].tags.join(',') };

    this.auth
      .post('/api/v1/update_recommendation', data)
      .subscribe((res: any) => {
        console.log(res);
        this.loadTags();
        setTimeout(() => {
          this.list[i].isUpdated = false;
        }, 2000);
      });
  }
}
