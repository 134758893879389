import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import {
  LocalDataSource,
  Ng2SmartTableModule,
} from '../../../libraries-forks/ng2-smart-table';
import { IApiResponse, IOfferList } from '../../models';
import { ApiMessageService, RequestService } from '../../services';
import { sleepUtil } from '../../utils';
import { offerTableColumns } from './offer-table-columns.config';


@Component({
  selector: 'fh-offer-list-page',
  templateUrl: './offer-list-page.component.html',
  styleUrls: ['./offer-list-page.component.scss'],
  standalone: true,
  imports: [
    Ng2SmartTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
})
export class OfferListPageComponent implements OnInit {
  readonly RECORDS_PER_PAGE = 20;

  settings: any;
  source: LocalDataSource = new LocalDataSource();

  constructor(
    private requestService: RequestService,
    private router: Router,
    private apiMessageService: ApiMessageService,

    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getOfferList();
  }
  getOfferList() {
    this.requestService
      .get<IApiResponse>(`/api/v1/offer/get_offer_code_details`)
      .subscribe((apiRes) => {
        this.changeSettingsForTable(apiRes.data);
      });
  }

  addRecord(event: any) {
    this.requestService
      .post<IApiResponse>('/api/v1/offer/add-new-offer', {
        ...event.newData,
      })
      .subscribe((apiRes) => {
        this.apiMessageService.manageAlertMessage({
          apiRes,
        });
        if (apiRes.result_code === 1) {
          this.getOfferList();
          event.confirm.resolve(event.newData);
        }
      });
  }

  updateRecord(event: any) {
    this.requestService
      .post<IApiResponse>('/api/v1/offer/update-offer-details', {
        newOfferDetails: event.newData,
      })
      .subscribe((apiRes) => {
        this.apiMessageService.manageAlertMessage({
          apiRes,
          showMessageFor: 3000,
        });
        if (apiRes.result_code === 1) {
          this.getOfferList();
          event.confirm.resolve(event.newData);
        }
      });
  }

  async changeSettingsForTable(offerList: IOfferList[]) {
    const offerCodeTableColumn = {
      offerId: offerTableColumns.offerId,
      offerCode: offerTableColumns.offerCode,
      offerCodeType: offerTableColumns.offerCodeType,
      startDate: offerTableColumns.startDate,
      expiryDate: offerTableColumns.expiryDate,
      discountType: offerTableColumns.discountType,
      minCartSubtotal: offerTableColumns.minCartSubtotal,
      percentageAmount: offerTableColumns.percentageAmount,
      status: offerTableColumns.status,
      offerCodeDiscountOn: offerTableColumns.offerCodeDiscountOn,
    };

    const tempSettings = {
      actions: { add: true, edit: true, delete: false, position: 'left' },
      add: {
        addButtonContent: '<span class="material-icons">add</span>',
        createButtonContent: '<span class="material-icons">done</span>',
        cancelButtonContent: '<span class="material-icons">close</span>',
        confirmCreate: true,
      },
      edit: {
        editButtonContent: '<span class="material-icons">edit</span>',
        saveButtonContent: '<span class="material-icons">done</span>',
        cancelButtonContent: '<span class="material-icons">close</span>',
        confirmSave: true,
      },
      columns: offerCodeTableColumn,
      pager: {
        display: true,
        perPage: this.RECORDS_PER_PAGE,
      },
    };
    await sleepUtil(100);

    this.settings = Object.assign({}, tempSettings);
    this.source.load(offerList);
    this.cdr.detectChanges();
  }
}
