<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">Recommendations Mapping</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead class="text-primary">
                  <tr>
                    <th>Quiz Response</th>
                    <th>Product Tags</th>
                    <th>changes</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  @for (row of list; track row.key; let i = $index) {
                  <tr>
                    <td class="text-capitalize">{{ row.key }}</td>
                    <td>
                      <fh-chips-multi-select
                        [existingValues]="row.tags"
                        (selectionChange)="list[i].tags = $event"
                      >
                      </fh-chips-multi-select>
                    </td>
                    <td>
                      <button
                        mat-raised-button
                        class="btn btn-danger"
                        (click)="saveTagForKey(i)"
                      >
                        SAVE
                      </button>
                    </td>
                    <td>
                      @if (row.isUpdated) {
                      <img
                        src="../../../assets/images/check.svg"
                        alt=""
                        class="info"
                        style="height: 20px"
                      />
                      }
                    </td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
