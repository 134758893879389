<div class="card">
  <div class="card-header card-header-danger">
    <h4 class="card-title">{{ formTitle }}</h4>
  </div>
  <div class="card-body">
    @if(productForm) {
    <form [formGroup]="productForm">
      <div class="row">
        @if (productNameControl) {
        <mat-form-field class="col-md-12">
          <mat-label>Product name</mat-label>
          <input
            matInput
            placeholder="Product Name"
            [formControl]="productNameControl"
            required
          />
          @if(productNameControl.invalid && (productNameControl.dirty ||
          productNameControl.touched)) {
          <mat-error>Product name is required</mat-error>
          }
        </mat-form-field>
        } @if(qtyControl) {

        <mat-form-field class="col-md-4">
          <mat-label>Quantity</mat-label>
          <input
            matInput
            placeholder="Quantity"
            [formControl]="qtyControl"
            required
          />
          @if (qtyControl.invalid && (qtyControl.dirty || qtyControl.touched)) {
          <mat-error>Quantity is required</mat-error>
          }
        </mat-form-field>
        } @if (originCountryIdControl) {

        <mat-form-field class="col-md-4">
          <mat-label>Origin Country</mat-label>
          <mat-select
            [formControl]="originCountryIdControl"
            (selectionChange)="onCountrySelectionChange($event)"
          >
            @for (row of countries; track row) {
            <mat-option [value]="row.id">
              {{ row.text }}
            </mat-option>
            }
          </mat-select>
          @if(originCountryIdControl.invalid && (originCountryIdControl.dirty ||
          originCountryIdControl.touched)) {
          <mat-error>Country is required</mat-error>
          }
        </mat-form-field>
        } @if (vendorIdControl) {
        <mat-form-field class="col-md-4">
          <mat-label>Vendor</mat-label>
          <mat-select
            [formControl]="vendorIdControl"
            (selectionChange)="onVendorSelectionChange($event.value)"
          >
            @for (row of vendors; track row) {
            <mat-option [value]="row.id">
              {{ row.first_name }}&nbsp;{{ row.last_name }}
            </mat-option>
            }
          </mat-select>
          @if(vendorIdControl.invalid && (vendorIdControl.dirty ||
          vendorIdControl.touched)) {
          <mat-error>Vendor is required</mat-error>
          }
        </mat-form-field>
        } @if (categoryIdControl) {

        <mat-form-field class="col-md-4">
          <mat-label>Category</mat-label>
          <mat-select
            [formControl]="categoryIdControl"
            (selectionChange)="onCategorySelectionChange($event.value)"
          >
            @for (row of categories; track row.id) {
            <mat-option [value]="row.id">
              {{ row.category_name }}
            </mat-option>
            }
          </mat-select>
          @if(categoryIdControl.invalid && (categoryIdControl.dirty ||
          categoryIdControl.touched)) {
          <mat-error>Category is required</mat-error>
          }
        </mat-form-field>
        } @if (subcategoryIdControl) {

        <mat-form-field class="col-md-4">
          <mat-label>Sub-Category</mat-label>
          <mat-select
            [formControl]="subcategoryIdControl"
            (selectionChange)="slectSubCategory($event.value)"
          >
            @for (row of subcategories; track row.id) {
            <mat-option [value]="row.id">
              {{ row.category_name }}
            </mat-option>
            }
          </mat-select>
          @if(subcategoryIdControl.invalid && (subcategoryIdControl.dirty ||
          subcategoryIdControl.touched)) {
          <mat-error>Sub-Category is required</mat-error>
          } </mat-form-field
        >} @if (subsubcategoryIdControl) {

        <mat-form-field class="col-md-4">
          <mat-label>Sub Sub-Category</mat-label>
          <mat-select [formControl]="subsubcategoryIdControl">
            <mat-option value="0"> </mat-option>
            @for (row of subsubcategories; track row.id) {
            <mat-option [value]="row.id">
              {{ row.category_name }}
            </mat-option>
            }
          </mat-select> </mat-form-field
        >} @if (minSizeControl) {
        <mat-form-field class="col-md-3">
          <mat-label>Minimum Size</mat-label>
          <input matInput placeholder="In cms" [formControl]="minSizeControl" />
          @if(minSizeControl.invalid && (minSizeControl.dirty ||
          minSizeControl.touched)) {
          <mat-error>Minimum Size is required</mat-error>
          } </mat-form-field
        >} @if (maxSizeControl) {

        <mat-form-field class="col-md-3">
          <mat-label>Maximum Size</mat-label>
          <input matInput placeholder="In cms" [formControl]="maxSizeControl" />
          @if(maxSizeControl.invalid && (maxSizeControl.dirty ||
          maxSizeControl.touched)) {
          <mat-error>Maximum Size is required</mat-error>
          } </mat-form-field
        >} @if(careLevelControl) {

        <mat-form-field class="col-md-3">
          <mat-label>Care Level</mat-label>
          <mat-select [formControl]="careLevelControl">
            <mat-option value="Low">Low</mat-option>
            <mat-option value="Medium">Medium</mat-option>
            <mat-option value="High">High</mat-option>
          </mat-select>
          @if (careLevelControl.invalid && (careLevelControl.dirty ||
          careLevelControl.touched)) {
          <mat-error>Care Level is required</mat-error>
          } </mat-form-field
        >} @if (temperamentControl) {

        <mat-form-field class="col-md-3">
          <mat-label>Temperament</mat-label>
          <mat-select [formControl]="temperamentControl">
            <mat-option value="Aggressive">Aggressive</mat-option>
            <mat-option value="Semi Aggressive">Semi Aggressive</mat-option>
            <mat-option value="Peaceful">Peaceful</mat-option>
          </mat-select>
          @if (temperamentControl.invalid && (temperamentControl.dirty ||
          temperamentControl.touched)) {
          <mat-error>Temperament is required</mat-error>
          } </mat-form-field
        >} @if(isPromotedControl) {
        <mat-form-field class="col-md-4">
          <mat-label>Promoted</mat-label>
          <mat-select [formControl]="isPromotedControl">
            <mat-option [value]="false">No</mat-option>
            <mat-option [value]="true">Yes</mat-option>
          </mat-select>
        </mat-form-field>
        } @if (isFeaturedControl) {
        <mat-form-field class="col-md-4">
          <mat-label>Is Featured</mat-label>
          <mat-select [formControl]="isFeaturedControl">
            <mat-option [value]="false">No</mat-option>
            <mat-option [value]="true">Yes</mat-option>
          </mat-select>
          @if(isFeaturedControl.invalid && (isFeaturedControl.dirty ||
          isFeaturedControl.touched)) {
          <mat-error>Is Featured is required</mat-error>
          }
        </mat-form-field>
        } @if (isExclusiveControl) {
        <mat-form-field class="col-md-4">
          <mat-label>Is Exclusive</mat-label>
          <mat-select [formControl]="isExclusiveControl">
            <mat-option [value]="false">No</mat-option>
            <mat-option [value]="true">Yes</mat-option>
          </mat-select>
          @if(isExclusiveControl.invalid && (isExclusiveControl.dirty ||
          isExclusiveControl.touched)) {
          <mat-error>Is Exclusive is required</mat-error>
          }
        </mat-form-field>
        } @if (isSeasonalControl) {
        <mat-form-field class="col-md-4">
          <mat-label>Seasonal</mat-label>
          <mat-select [formControl]="isSeasonalControl">
            <mat-option [value]="true">Yes</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>
          @if(isSeasonalControl.invalid && (isSeasonalControl.dirty ||
          isSeasonalControl.touched)) {
          <mat-error>Seasonal is required</mat-error>
          } </mat-form-field
        >} @if (availableInBuildAquariumControl) {

        <mat-form-field class="col-md-4">
          <mat-label>Available in build aquarium</mat-label>
          <mat-select [formControl]="availableInBuildAquariumControl">
            <mat-option [value]="false">No</mat-option>
            <mat-option [value]="true">Yes</mat-option>
          </mat-select>
        </mat-form-field>
        } @if (productExpiryControl) {
        <mat-form-field class="col-md-4">
          <mat-label>Expiry</mat-label>
          <mat-select
            [formControl]="productExpiryControl"
            (selectionChange)="onExpirySelectionChange($event.value)"
          >
            @for (expiry of productExpiryOptions; track expiry) {
            <mat-option [value]="expiry.value"> {{ expiry.label }}</mat-option>
            }
          </mat-select>
          <mat-hint>
            {{ productExpiryHintText }}
          </mat-hint>
        </mat-form-field>
        } @if (statusControl) {
        <mat-form-field class="col-md-4">
          <mat-label>Visibility</mat-label>
          <mat-select [formControl]="statusControl">
            @for(status of productListingStatusOptions; track status.key) {
            <mat-option [value]="status.key">
              {{ status.value }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
        }@if(tankBreadthControl) {
        <mat-form-field class="col-md-4">
          <mat-label>Breadth in cms</mat-label>
          <input
            matInput
            placeholder="Breadth"
            [formControl]="tankBreadthControl"
            required
          />
          @if (tankBreadthControl.invalid && (tankBreadthControl.dirty ||
          tankBreadthControl.touched)) {
          <mat-error>Quantity is required</mat-error>
          }
        </mat-form-field>
        }@if(tankHeightControl) {
        <mat-form-field class="col-md-4">
          <mat-label>Height in cms</mat-label>
          <input
            matInput
            placeholder="Height"
            [formControl]="tankHeightControl"
            required
          />
          @if (tankHeightControl.invalid && (tankHeightControl.dirty ||
          tankHeightControl.touched)) {
          <mat-error>Quantity is required</mat-error>
          }
        </mat-form-field>
        }@if(tankLengthControl) {
        <mat-form-field class="col-md-4">
          <mat-label>Length in cms</mat-label>
          <input
            matInput
            placeholder="Length"
            [formControl]="tankLengthControl"
            required
          />
          @if (tankLengthControl.invalid && (tankLengthControl.dirty ||
          tankLengthControl.touched)) {
          <mat-error>Quantity is required</mat-error>
          }
        </mat-form-field>
        } @if(capacityControl) {
        <mat-form-field class="col-md-4">
          <mat-label>Capacity in gallons</mat-label>
          <input
            matInput
            placeholder="Capacity"
            [formControl]="capacityControl"
            required
          />
          @if (capacityControl.invalid && (capacityControl.dirty ||
          capacityControl.touched)) {
          <mat-error>Capacity for tank is required</mat-error>
          }
        </mat-form-field>
        } @if (tagsDataControl) {
        <div class="col-md-12">
          <fh-chips-multi-select label="Tags" [formControl]="tagsDataControl">
          </fh-chips-multi-select>
        </div>
        } @if(mediaPickerControl) {

        <fh-media-picker [formControl]="mediaPickerControl"></fh-media-picker>
        }
        <div class="col-md-12 country-specific">
          <div class="col-md-12 row price-section">
            @if(destinationCountryIdControl) {
            <mat-form-field class="col-md-4">
              <mat-label>Destination Country</mat-label>
              <mat-select [formControl]="destinationCountryIdControl">
                @for (row of countries; track row) {
                <mat-option [value]="row.id">
                  {{ row.text }}
                </mat-option>
                }
              </mat-select>
              @if(destinationCountryIdControl.invalid &&
              (destinationCountryIdControl.dirty ||
              destinationCountryIdControl.touched)) {
              <mat-error>Country is required</mat-error>
              }
            </mat-form-field>
            } @if(displayPriceControl) {
            <mat-form-field class="col-md-4">
              <mat-label>Display Price</mat-label>
              <input
                matInput
                placeholder="$ Display Price"
                [formControl]="displayPriceControl"
              />
              @if (displayPriceControl.invalid && (displayPriceControl.dirty ||
              displayPriceControl.touched)) {
              <mat-error>
                @if (displayPriceControl.errors?.['pattern ']) {
                <span class="text-danger"
                  >Display price must be a numerical value.</span
                >
                }
              </mat-error>
              }
            </mat-form-field>
            } @if (destinationTranshipperMarkupControl) {
            <mat-form-field class="col-md-4">
              <mat-label>Destination Transhipper Markup</mat-label>
              <input
                matInput
                placeholder="$ Sell Price"
                [formControl]="destinationTranshipperMarkupControl"
                required
              />
              @if (destinationTranshipperMarkupControl.invalid &&
              (destinationTranshipperMarkupControl.dirty ||
              destinationTranshipperMarkupControl.touched)) {
              <mat-error
                >Destination Transhipper Markup Price is required</mat-error
              >
              }
            </mat-form-field>
            }
          </div>
          <div class="col-md-12 row delivery-section">
            @if(standardDeliveryPriceControl) {
            <mat-form-field class="col-md-12">
              <mat-label>Standard Delivery Charges</mat-label>
              <input
                matInput
                placeholder="$ Standard Delivery Charges"
                [formControl]="standardDeliveryPriceControl"
                required
              />
              @if (standardDeliveryPriceControl.invalid &&
              (standardDeliveryPriceControl.dirty ||
              standardDeliveryPriceControl.touched)) {
              <mat-error>Standard Delivery Charges is required</mat-error>
              }
            </mat-form-field>
            }
          </div>

          @if(moreDescriptionControl) {
          <div class="col-md-12">
            <strong>Product Description</strong>
            <angular-editor
              [formControl]="moreDescriptionControl"
              [placeholder]="'More Description*'"
              [config]="editorConfig"
            ></angular-editor>
            @if (moreDescriptionControl.invalid && (moreDescriptionControl.dirty
            || moreDescriptionControl.touched)) {
            <mat-error>More Description is required</mat-error>
            }
            <br />
          </div>
          } @if (productOptionsControl) {
          <div class="col-md-12">
            <strong>Product Options</strong>
            <fh-product-options
              [formControl]="productOptionsControl"
              [price]="1"
            >
            </fh-product-options>
          </div>
          }
        </div>
      </div>
    </form>
    }
  </div>
</div>
