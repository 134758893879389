import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { provideNativeDateAdapter } from '@angular/material/core';
import {
  MatDateRangeInput,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterLink } from '@angular/router';
import { IApiResponse } from '@models/api-response.model';
import { IVendors } from '@models/vendors.model';
import { NotificationService } from '@services/index';
import { RequestService } from '@services/request/request.service';

@Component({
  selector: 'fh-merchant-orders',
  templateUrl: './merchant-orders.component.html',
  styleUrls: ['./merchant-orders.component.scss'],
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatDateRangeInput,
    MatPaginatorModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    RouterLink,
    DecimalPipe,
    DatePipe,
  ],
})
export class MerchantOrdersComponent implements OnInit {
  access = localStorage.getItem('aqua_access');
  list: any[] = [];
  total = 0;
  pageIndex = 0;
  size = 0;
  url!: string;
  download: any;
  remarks = {
    orderId: '',
    vendorId: '',
    text: '',
  };
  vendors: IVendors[] = [];
  vendorId = 0;
  range = new UntypedFormGroup({
    start: new UntypedFormControl('', Validators.required),
    end: new UntypedFormControl('', Validators.required),
  });
  date = {
    start: '',
    end: '',
  };
  constructor(
    private auth: RequestService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.access = localStorage.getItem('aqua_access');
    this.fetchOrders();
    this.getvendors();
  }

  fetchOrders() {
    const data = {
      pageIndex: this.pageIndex,
      pageSize: 40,
      start: '',
      end: '',
      vendorId: this.vendorId,
    };
    this.auth
      .post<IApiResponse>('/api/v1/merchantOrders', data)
      .subscribe((res) => {
        if (res.result_code === 1) {
          this.list = res.data.list;
          this.total = res.data.count;
          this.size = res.data.size;
        }
      });
  }

  fixDecimal = (unformatMerchantOrderList: any[]) => {
    const formatMerchantOrderList = unformatMerchantOrderList.map(
      (val: {
        plantsAndLivestock: number;
        others: number;
        total: number;
        ordered_on: string | number | Date;
      }) => {
        return {
          ...val,
          plantsAndLivestock: val.plantsAndLivestock
            ? val.plantsAndLivestock.toFixed(2)
            : 0.0,
          others: val.others ? val.others.toFixed(2) : 0.0,
          total: val.total ? val.total.toFixed(2) : 0.0,
          ordered_on: new Date(val.ordered_on),
        };
      }
    );
    return formatMerchantOrderList;
  };

  checkDateRange = () => {
    if (
      this.range.controls['end'].errors ||
      this.range.controls['end'].pristine
    ) {
      return false;
    }
    if (
      this.range.controls['start'].errors ||
      this.range.controls['end'].pristine
    ) {
      return false;
    }
    const a = new Date(this.range.controls['start'].value);
    const b = new Date(this.range.controls['end'].value);
    if (a > b) {
      return false;
    }
    return true;
  };

  formatToString = (list: any[]) => {
    const heading =
      'order_id,customer,vendor_id,merchant,ordered_on,status,order_status_remarks,remarks,delivery_remarks,total,shipping_total,plantsAndLivestock,others \n';
    const csvData = list.map(
      (val: {
        order_id: any;
        customer: any;
        vendor_id: any;
        merchant: any;
        ordered_on: any;
        status: any;
        order_remarks: string;
        remarks: string;
        delivery_remarks: string;
        total: any;
        shipping_total: any;
        plantsAndLivestock: any;
        others: any;
      }) => {
        let data = '';
        data = `${val.order_id},${val.customer},${val.vendor_id},${
          val.merchant
        },${val.ordered_on},${val.status},${
          val.order_remarks?.replace(/,/g, '&') ?? ''
        },${val.remarks?.replace(/,/g, '&') ?? ''},${
          val.delivery_remarks?.replace(/,/g, '&') ?? ''
        },${val.total},${val.shipping_total},${val.plantsAndLivestock},${
          val.others
        }\n`;
        return data;
      }
    );
    csvData.unshift(heading);
    return csvData;
  };

  clearDatePicker() {
    this.range.reset();
    this.date = {
      start: '',
      end: '',
    };
  }

  downloadCSV = (): any => {
    // check if user set start and end date
    if (!this.checkDateRange()) {
      this.errorMessage('Given time range is invalid');
      return 0;
    }
    // fetch data
    try {
      this.date = JSON.parse(JSON.stringify(this.range.value));
      const data = {
        pageIndex: '',
        pageSize: '',
        start: this.date.start,
        end: this.date.end,
        vendorId: this.vendorId,
      };
      this.auth
        .post<IApiResponse>('/api/v1/merchantOrders', data)
        .subscribe((res): any => {
          if (res.result_code === 1) {
            const fetchMerchantOrders = res.data.list;
            if (!fetchMerchantOrders) {
              throw new Error('Couldnt fetch orders');
            }
            // format amount to get fixed value after 2 digits and format date
            const merchantOrders = this.fixDecimal(fetchMerchantOrders);
            if (!merchantOrders) {
              throw new Error('Couldnt fix Decimal 2 digits after');
            }
            // format the JSON to a string
            const merchantOrdersString = this.formatToString(merchantOrders);
            if (!merchantOrdersString) {
              throw new Error('Couldnt format to string');
            }
            // download it
            const data = new Blob(merchantOrdersString, { type: 'text/csv' });
            const csvURL = window.URL.createObjectURL(data);
            const tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', 'merchantOrder.csv');
            tempLink.click();
          } else {
            return false;
          }
        });
      // return merchantOrders;
    } catch (error) {
      console.error(error);
    }
  };

  onPageChange(pageSettings: any) {
    this.pageIndex = pageSettings.pageIndex;
    this.fetchOrders();
  }

  // for saving delivery remark
  onChange(orderId: string, vendorId: string, changeEvent?: any) {
    this.remarks.orderId = orderId;
    this.remarks.vendorId = vendorId;
    if (changeEvent) {
      this.remarks.text = changeEvent.target.value;
    }
  }

  // send saved delivery remark to backend
  submitRemark(orderId: string, vendorId: string): any {
    if (
      this.remarks.orderId !== orderId ||
      this.remarks.vendorId !== vendorId
    ) {
      this.remarks = { orderId: '', vendorId: '', text: '' };
      this.errorMessage("Sorry,remarks could'nt be saved please try again!");
      this.fetchOrders();
      return 0;
    }
    this.successMessage('Please wait, saving...');
    const data = this.remarks;
    this.auth
      .post<IApiResponse>('/api/v1/update_delivery_remarks', data)
      .subscribe((res) => {
        if (res.result_code === 1) {
          this.successMessage('Successfully updated remarks!!!');
          this.fetchOrders();
        } else {
          this.errorMessage(
            'Error occurred while updating remarks, Please try again!!!'
          );
        }
      });
    this.clearRemark();
  }

  getvendors() {
    this.auth
      .get<IApiResponse>('/api/v1/get_vendors_with_orders')
      .subscribe((res) => {
        if (res.result_code === 1) {
          this.vendors = res.data;
        }
      });
  }

  // clear control over a delivery remark input field
  clearRemark() {
    this.remarks.orderId = '';
    this.remarks.vendorId = '';
    this.remarks.text = '';
    this.fetchOrders();
  }

  successMessage(message: string) {
    this.notificationService.show({ message: message });
  }

  errorMessage(message: string) {
    this.notificationService.show({ message: message, type: 'danger' });
  }

  updateDeliveryStatus(orderId: string, vendorId: number, status: string) {
    const data = {
      orderId,
      vendorId,
      status,
    };
    this.auth
      .post<IApiResponse>('/api/v1/update_delivery_remarks', data)
      .subscribe((res) => {
        if (res.result_code === 1) {
          this.successMessage('Successfully updated status');
          this.fetchOrders();
        } else {
          this.errorMessage('Error occurred while updating status');
        }
      });
  }

  onMerchantSelection = (merchantId: number) => {
    // console.log(merchantId);
    this.vendorId = merchantId;
    this.fetchOrders();
  };
}
