<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title ">Promo Codes List</h4>
          </div>
          <div class="card-body">
            <div class="promo-list-page__table-container">
              @if (settings) {
                <fh-smart-table
                  [settings]="settings"
                  [source]="source"
                  (createConfirm)="addRecord($event)"
                  (editConfirm)="updateRecord($event)"
                  >
                </fh-smart-table>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
